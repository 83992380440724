import { gql } from '@apollo/client';

export default gql`
  query entryById($id: ID!) {
    entryById(id: $id) {
      id
      itemId
      balanceQty
      description
      createdAt
      orderDate
      shipToAddress
      city
      zip
      state
      cost
      airFreightCostPerPiece
      moldCost
      sampleCost
      otherCost
      orderStatus
      orderCategory
      pricePerPiece
      discount
      orderAmount
      orderType
      shipToQuantity
      dueDate
      remarks
      customerId
      customerType
      endCustomerId
      childSplits {
        id
        batchId
        batchTitle
        shipToQuantity
        airFreightCost
        localTruckingCost
        tariffs
        otherAdditionalCharges
        discount
        executedDate
        invoiceAmount
        totalExecuted
        pricePerPiece
        updatedAt
        createdAt
      }
      endCustomer {
        id
        title
      }
      vendorId
      batchId
      poId
      orderQuantity
      invoiceAmount
      paymentType
      tariffsPaid
      customsFees
      dittoTruckingCost
      GRT
      executedDate
      paymentDate
      etdChina
      etdLaPort
      airFreightCost
      localTruckingCost
      tariffs
      otherAdditionalCharges
      totalExecuted
      poStatus
      vendor {
        id
        title
      }
      customer {
        id
        title
      }
      batch {
        id
        title
      }
      purchaseOrder {
        id
        title
      }
    }
  }
`;
