// TOP LEVEL IMPORTS
import { gql } from '@apollo/client';

export default gql`
  mutation changeOrderStatus($id: ID, $params: ChangeOrderStatusInput!) {
    changeOrderStatus(id: $id, params: $params) {
      id
      balanceQty
      orderStatus
    }
  }
`;
