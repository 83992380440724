import {gql} from '@apollo/client';

export default gql`
  query reportSales($startDate: String, $endDate: String) {
    reportSales(startDate: $startDate, endDate: $endDate) {
      underExecution
      executed
      newSales
      total
    }
  }
`;
