import React from 'react';
import Select from 'components/inputs/SelectInput';
import DropdownStyleWrapper from 'components/inputs/DropdownStyleWrapper';

const { Option } = Select;

export default function OrderStatusInput({ value, onChange }) {
  return (
    <DropdownStyleWrapper>
      <Select
        value={value}
        placeholder='Order Status'
        style={{ width: '100%' }}
        onChange={onChange}
      >
        <Option value='underExecution'>UNDER EXECUTION</Option>
        <Option value='partiallyExecuted'>PARTIALLY UNDER EXECUTION</Option>
        <Option value='executed'>EXECUTED</Option>
        <Option value='executedWithSplits'>EXECUTED (With Splits)</Option>
      </Select>
    </DropdownStyleWrapper>
  );
}
