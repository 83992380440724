import React from 'react';
import {useLocation} from 'react-router-dom';
import styled from 'styled-components';
// COMPONENTS
import Header from './Header';
import AppNav from './AppNav';

const Container = styled.div`
  margin: auto;
  width: 1150px;
  max-width: 90%;
  padding-top: 16px;
  padding-bottom: 56px;
  min-height: calc(100vh - 289px);
`;

export default function AdminLayout({currentUser, children}) {
  const location = useLocation();
  return (
    <div>
      <Header currentUser={currentUser} />
      <AppNav pathname={location.pathname} />
      <Container>{children}</Container>
    </div>
  );
}
