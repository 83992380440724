// TOP LEVEL IMPORTS
import {gql} from '@apollo/client';

export default gql`
  mutation saveCustomer($id: ID, $title: String!) {
    saveCustomer(id: $id, title: $title) {
      id
    }
  }
`;
