import {gql} from '@apollo/client';

export default gql`
  query reportSalesByTop5($startDate: String, $endDate: String) {
    reportSalesByTop5(startDate: $startDate, endDate: $endDate) {
      customer
      underExecution
      executed
      newSales
      total
    }
  }
`;
