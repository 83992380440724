import { useState } from 'react';
import moment from 'moment';
import numeral from 'numeral';
import Papa from 'papaparse';
// APOLLO CLIENT
import ENTRIES_DOWNLOAD from 'ApolloClient/Queries/entriesCSV';
import client from 'ApolloClient/index.js';
// LIB
import helpers from 'lib/helpers/index';

export default function useDownloadCSV(variables) {
  const [downloading, setDownloading] = useState(false);

  const cleanData = (data, full, executedData) => {
    if (!full) {
      return data.map((item) => {
        return {
          PO: item.purchaseOrder && item.purchaseOrder.title,
          Batch: item.batch && item.batch.title,
          Customer: item.customer && item.customer.title,
          'Item Number': item.itemId,
          'Order Date': moment(parseInt(item.orderDate)).format('MM/DD/YYYY'),
          Description: item.description && item.description.trim(),
          Quantity: item.orderQuantity,
          Remarks: item.remarks,
          'Due Date': moment(parseInt(item.dueDate)).format('MM/DD/YYYY'),
        };
      });
    }

    let entriesData = data.map((item) => {
      // Creating the Child Entries Here
      if (item.childSplits && item.childSplits.length > 0) {
        let childEntries = item.childSplits.map((child) => {
          return {
            PO: item.purchaseOrder && item.purchaseOrder.title,
            Batch: child.batchTitle,
            Customer: item.customer && item.customer.title,
            'Item Number': item.itemId,
            Status: 'SPLIT ENTRY',
            'Order Date': moment(parseInt(item.orderDate)).format(
              'MM/DD/YYYY h:mma'
            ),
            'Entry Date': moment(parseInt(child.createdAt)).format(
              'MM/DD/YYYY h:mma'
            ),
            Description: item.description && item.description.trim(),
            'Product Category': helpers.getCategoryLabelFromValue(
              item.orderCategory
            ),
            'Order Type': helpers.getTypeLabelFromValue(item.orderType),
            Vendor: item.vendor && item.vendor.title,
            'Price per Piece': item.pricePerPiece,
            'ORDER Quantity': 0,
            'ORDER Amount': numeral(0).format('$0,0.00'),
            Remarks: item.remarks,
            'Cost Per Piece': item.cost,
            'Other Additional Charges': child.otherAdditionalCharges,
            'Air Freight Price': child.airFreightCost,
            'Local Trucking Cost': child.localTruckingCost,
            Tariffs: child.tariffs,
            Discount: child.discount,
            'Ship to Quantity': child.shipToQuantity,
            'Due Date': moment(parseInt(item.dueDate)).format('MM/DD/YYYY'),
            'Invoice Amount': child.invoiceAmount,
            Address: item.shipToAddress,
            City: item.city,
            Zip: item.zip,
            State: item.state,
            'Payment Type': item.paymentType,
            GRT: item.GRT,
            'Payment Date': item.paymentDate
              ? moment(parseInt(item.paymentDate)).format('MM/DD/YYYY')
              : null,
            'ETD China': item.etdChina
              ? moment(parseInt(item.etdChina)).format('MM/DD/YYYY')
              : null,
            'ETD LA Port': item.etdChina
              ? moment(parseInt(item.etdLaPort)).format('MM/DD/YYYY')
              : null,
            'Total Executed': child.totalExecuted,
            'PO Status': item.poStatus,
            'Tariffs Paid': item.tariffsPaid,
            'Customs Brokerage Fees': item.customsFees,
            'Ditto Trucking Cost': item.dittoTruckingCost,
            'Last Updated': item.updatedAt
              ? moment(parseInt(child.updatedAt)).format('MM/DD/YYYY h:mma')
              : null,
            'Executed On Date': child.executedDate
              ? moment(parseInt(child.executedDate)).format('MM/DD/YYYY')
              : null,
            'End Customer': item.endCustomer && item.endCustomer.title,
            DB_ID: child.id,
          };
        });

        let parentEntry = [
          {
            PO: item.purchaseOrder && item.purchaseOrder.title,
            Batch: item.batch && item.batch.title,
            Customer: item.customer && item.customer.title,
            'Item Number': item.itemId,
            Status: helpers.getStatusLabel(item.orderStatus),
            'Order Date': moment(parseInt(item.orderDate)).format(
              'MM/DD/YYYY h:mma'
            ),
            'Entry Date': moment(parseInt(item.createdAt)).format(
              'MM/DD/YYYY h:mma'
            ),
            Description: item.description && item.description.trim(),
            'Product Category': helpers.getCategoryLabelFromValue(
              item.orderCategory
            ),
            'Order Type': helpers.getTypeLabelFromValue(item.orderType),
            'Other Additional Charges': null,
            'Air Freight Price': item.airFreightCost,
            'Local Trucking Cost': null,
            Tariffs: null,
            Discount: null,
            'Ship to Quantity': null,
            Vendor: item.vendor && item.vendor.title,
            'Price per Piece': item.pricePerPiece,
            'ORDER Quantity': item.orderQuantity,
            'ORDER Amount': numeral(item.orderAmount).format('$0,0.00'),
            Remarks: item.remarks,
            'Cost Per Piece': item.cost,
            'Air Freight Cost': item.airFreightCostPerPiece,
            'Mold Cost': item.moldCost,
            'Sample Cost': item.sampleCost,
            'Other Cost': item.otherCost,
            'Balance Quantity': item.balanceQty,
            'Due Date': moment(parseInt(item.dueDate)).format('MM/DD/YYYY'),
            'Invoice Amount': null,
            Address: item.shipToAddress,
            City: item.city,
            Zip: item.zip,
            State: item.state,
            'Payment Type': item.paymentType,
            GRT: item.GRT,
            'Payment Date': item.paymentDate
              ? moment(parseInt(item.paymentDate)).format('MM/DD/YYYY')
              : null,
            'ETD China': item.etdChina
              ? moment(parseInt(item.etdChina)).format('MM/DD/YYYY')
              : null,
            'ETD LA Port': item.etdChina
              ? moment(parseInt(item.etdLaPort)).format('MM/DD/YYYY')
              : null,
            'Total Executed': null,
            'PO Status': item.poStatus,
            'Tariffs Paid': item.tariffsPaid,
            'Customs Brokerage Fees': item.customsFees,
            'Ditto Trucking Cost': item.dittoTruckingCost,
            'Last Updated': item.updatedAt
              ? moment(parseInt(item.updatedAt)).format('MM/DD/YYYY h:mma')
              : null,
            'Executed On Date': null,
            'End Customer': item.endCustomer && item.endCustomer.title,
            DB_ID: item.id,
          },
          ...childEntries,
        ];
        return parentEntry;
      }

      let parentEntry = {
        PO: item.purchaseOrder && item.purchaseOrder.title,
        Batch: item.batch && item.batch.title,
        Customer: item.customer && item.customer.title,
        'Item Number': item.itemId,
        Status: helpers.getStatusLabel(item.orderStatus),
        'Order Date': moment(parseInt(item.orderDate)).format(
          'MM/DD/YYYY h:mma'
        ),
        'Entry Date': moment(parseInt(item.createdAt)).format(
          'MM/DD/YYYY h:mma'
        ),
        Description: item.description && item.description.trim(),
        'Product Category': helpers.getCategoryLabelFromValue(
          item.orderCategory
        ),
        'Order Type': helpers.getTypeLabelFromValue(item.orderType),
        Vendor: item.vendor && item.vendor.title,
        'Price per Piece': item.pricePerPiece,
        'ORDER Quantity': item.orderQuantity,
        'ORDER Amount': numeral(item.orderAmount).format('$0,0.00'),
        Remarks: item.remarks,
        'Cost Per Piece': item.cost,
        'Air Freight Cost': item.airFreightCostPerPiece,
        'Mold Cost': item.moldCost,
        'Sample Cost': item.sampleCost,
        'Other Cost': item.otherCost,
        'Balance Quantity': item.balanceQty,
        'Other Additional Charges': item.otherAdditionalCharges,
        'Air Freight Price': item.airFreightCost,
        'Local Trucking Cost': item.localTruckingCost,
        Tariffs: item.tariffs,
        Discount: item.discount,
        'Ship to Quantity': item.shipToQuantity,
        'Due Date': moment(parseInt(item.dueDate)).format('MM/DD/YYYY'),
        'Invoice Amount': item.invoiceAmount,
        Address: item.shipToAddress,
        City: item.city,
        Zip: item.zip,
        State: item.state,
        'Payment Type': item.paymentType,
        GRT: item.GRT,
        'Payment Date': item.paymentDate
          ? moment(parseInt(item.paymentDate)).format('MM/DD/YYYY')
          : null,
        'ETD China': item.etdChina
          ? moment(parseInt(item.etdChina)).format('MM/DD/YYYY')
          : null,
        'ETD LA Port': item.etdChina
          ? moment(parseInt(item.etdLaPort)).format('MM/DD/YYYY')
          : null,
        'Total Executed': item.totalExecuted,
        'PO Status': item.poStatus,
        'Tariffs Paid': item.tariffsPaid,
        'Customs Brokerage Fees': item.customsFees,
        'Ditto Trucking Cost': item.dittoTruckingCost,
        'Last Updated': item.updatedAt
          ? moment(parseInt(item.updatedAt)).format('MM/DD/YYYY h:mma')
          : null,
        'Executed On Date': item.executedDate
          ? moment(parseInt(item.executedDate)).format('MM/DD/YYYY')
          : null,
        'End Customer': item.endCustomer && item.endCustomer.title,
        DB_ID: item.id,
      };

      return parentEntry;
    });

    if (executedData) {
      entriesData = entriesData
        .flat()
        .filter(
          (row) =>
            row.Status !== 'PARTIALLY UNDER EXECUTION' &&
            row.Status !== 'EXECUTED WITH SPLITS'
        );
    } else {
      entriesData = entriesData.flat();
    }

    return entriesData;
  };

  const downloadFile = (
    dataSource,
    exportFilename = 'data.csv',
    full,
    executedData
  ) => {
    let data = Papa.unparse(cleanData(dataSource, full, executedData), {
      header: true,
    });
    let csvData = new Blob([data], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(csvData, exportFilename);
    } else {
      // In FF link must be added to DOM to be clicked
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(csvData);
      link.setAttribute('download', exportFilename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    setDownloading(false);
  };

  const onDownload = async ({
    full = true, // should the CSV be formatted as the "full" template version or abbreviated CSV template
    fullHistory = false, // should we ignore the dates and export all data in the DB
    executedData = false, // should we get just executed data for export?
  }) => {
    try {
      setDownloading(true);

      let res = await client.query({
        query: ENTRIES_DOWNLOAD,
        fetchPolicy: 'network-only',
        variables: {
          startDate: variables.startDate,
          endDate: variables.endDate,
          fullHistory,
          executedData,
          skip: 0,
          limit: 50000,
        },
      });
      downloadFile(res.data.entriesCSV, undefined, full, executedData);
    } catch (err) {
      setDownloading(false);
      console.log(err);
    }
  };

  return {
    downloading,
    onDownload,
  };
}
