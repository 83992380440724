import React from 'react';
import Select from 'components/inputs/SelectInput';
import DropdownStyleWrapper from 'components/inputs/DropdownStyleWrapper';

const {Option} = Select;

export default function PaymentTypeInput({value, onChange}) {
  return (
    <DropdownStyleWrapper>
      <Select
        value={value}
        placeholder="Payment Type"
        style={{width: '100%'}}
        onChange={onChange}
      >
        <Option value="TT">TT</Option>
        <Option value="LC">LC</Option>
      </Select>
    </DropdownStyleWrapper>
  );
}
