import {
  ApolloClient,
  HttpLink,
  ApolloLink,
  InMemoryCache,
} from '@apollo/client';
import authLink from './links/authLink';
import logger from 'apollo-link-logger';

// for some reason, need to pass uri in via links like https://github.com/apollographql/apollo-link/issues/1246#issuecomment-656092271
let links = [
  authLink,
  logger,
  new HttpLink({
    uri: process.env.REACT_APP_API_HOST || 'http://localhost:8080/graphql',
  }),
];

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from(links),
});

export default client;
