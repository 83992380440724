import React from 'react';
import Select from 'components/inputs/SelectInput';
import DropdownStyleWrapper from 'components/inputs/DropdownStyleWrapper';
import {ORDER_CATEGORIES} from 'lib/helpers/index.js';

const {Option} = Select;

/**
 * NOTE THIS WAS FORMALLY REFERRED TO AS "ORDER CATEGORY"
 */

export default function OrderCategory({value, onChange}) {
  return (
    <DropdownStyleWrapper>
      <Select
        value={value}
        placeholder="Product Category"
        style={{width: '100%'}}
        onChange={onChange}
      >
        {ORDER_CATEGORIES.map((item) => (
          <Option key={item.value} value={item.value}>
            {item.label}
          </Option>
        ))}
      </Select>
    </DropdownStyleWrapper>
  );
}
