import styled from 'styled-components';

export default styled.div`
  .ant-select {
    height: 50px;
    background: ${(p) => p.theme.colors.primary4};
    border: 1px solid ${(p) => p.theme.colors.primary4};
    border-radius: 5px;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 50px;
    align-items: center;
    display: flex;
    border-radius: 5px;
    border: 1px solid ${(p) => p.theme.colors.primary4};
    background: ${(p) => p.theme.colors.primary4};
  }
  .ant-select-selection-search-input {
    height: 48px !important;
  }

  .ant-select-selection-placeholder {
    color: ${(p) => p.theme.colors.placeholder};
    opacity: 1;
  }

  /* .ant-select-selection-search-input :focus {
    outline: 0;
    background: ${(
    p
  ) => p.theme.colors.activeFieldBackground};
    color: ${(p) =>
    p.theme.colors.activeFieldText};
  } */
`;
