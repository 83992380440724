import React from 'react';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';
import Row from 'components/common/Row';
import Col from 'components/common/Col';
// APOLLO
import AuthHelpers from 'lib/helpers/AuthHelpers';
import ApolloClient from 'ApolloClient/index.js';
// LIB
import logo from 'lib/media/logo.png';

const HeaderContainer = styled.div`
  /* background: ${(p) => p.theme.colors.primary1}; */
  background: #000;
  font-family: ${(p) => p.theme.fontFamily};
`;

const Username = styled.div`
  color: #fff;
  font-family: ${(p) => p.theme.fontFamily};
  font-weight: 400;
  margin-left: auto;
`;

const SignoutBtn = styled.button`
  border: 0px;
  background: transparent;
  color: ${(p) => p.theme.colors.support1};
  margin: 0px;
  padding: 0px;
  font-size: 16px;
  margin-left: 8px;
  font-family: ${(p) => p.theme.fontFamily};
  text-decoration: underline;
  cursor: pointer;
  font-weight: 400;
`;

const Header = ({currentUser}) => {
  const history = useHistory();

  const onLogout = async () => {
    await AuthHelpers.signOut(currentUser.id);
    await ApolloClient.resetStore();
    return history.push('/');
  };

  return (
    <HeaderContainer>
      <Row
        style={{
          height: 120,
          margin: 'auto',
          width: 1150,
          maxWidth: '90%',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {' '}
        <Col xs={6}>
          <img
            src={logo}
            height="72"
            alt="logo"
            style={{marginBottom: 16, position: 'relative', left: -24}}
          />
        </Col>
        <Col xs={10}></Col>
        <Col xs={8} style={{textAlign: 'right'}}>
          <Username>
            logged-in as <strong>{currentUser.email}</strong>{' '}
            <SignoutBtn onClick={onLogout}>sign-out</SignoutBtn>
          </Username>
        </Col>
      </Row>{' '}
    </HeaderContainer>
  );
};

export default Header;
