import React, { useState } from 'react';
// COMONNENTS
import LinkButton from 'components/common/LinkButton';
import EntryForm from 'components/common/EntryForm';
import { LoadingOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import message from 'components/common/message';
// APOLLO
import { useMutation, useQuery } from '@apollo/client';
import ENTRY_BY_D from 'ApolloClient/Queries/entryById';
import DELETE_ENTRY from 'ApolloClient/Mutations/deleteEntry';
// LIB
import useOnSaveEntry from 'lib/hooks/useOnSaveEntry';
import helpers from 'lib/helpers';
import AntdNotification from 'components/common/notification';

const Loading = ({ text = 'Deleting...' }) => {
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 10,
        color: '#fff',
        textAlign: 'center',
        background: 'rgba(0,0,0,.45)',
      }}
    >
      <div>
        <LoadingOutlined style={{ fontSize: 20 }} />
        <p style={{ fontSize: 20, marginTop: 8 }}>{text}</p>
      </div>
    </div>
  );
};

export default function EditEntry({ id, onCancel, refetchQueries }) {
  const [values, setValues] = useState({});
  const [childValues, setChildValues] = useState([]);
  const [deleteEntry] = useMutation(DELETE_ENTRY);
  const [deleting, setDeleting] = useState(false);
  const entryById = useQuery(ENTRY_BY_D, {
    variables: {
      id,
    },
    onCompleted: (data) => {
      setValues(data.entryById);
    },
  });
  const openNotificationWithIcon = (type) => {
    AntdNotification[type]({
      message: 'Split Order Complete',
      description:
        'Ship To Quantity is greater than or equal to balance quantity. This will be your final split for this entry and it’s status will be changed to “EXECUTED (with splits)',
      duration: 0,
    });
  };
  const onAfterSubmit = () => {
    message.success('Entry was successfully saved');
    onCancel();
  };

  const { errors, loading, onSubmit, setErrors } = useOnSaveEntry({
    id,
    values,
    onAfterSubmit,
    refetchQueries: [
      ...refetchQueries,
      {
        query: ENTRY_BY_D,
        variables: {
          id,
        },
      },
    ],
  });

  const onChange = (newValue) => {
    const newVersion = {
      ...values,
      ...newValue,
    };

    const invoiceAmount = helpers.recalcInvoiceAmount({
      ...newVersion,
    });

    let totalExecuted = newVersion.totalExecuted;

    // we only want to re-calculate total executed if the orderStatus is executed
    if (newVersion.orderStatus === 'executed') {
      totalExecuted = helpers.recalcTotalExecuted({
        ...newVersion,
        invoiceAmount,
      });
    }

    const orderAmount = helpers.recalcOrderAmount({
      ...newVersion,
    });

    setValues({
      ...newVersion,
      totalExecuted,
      invoiceAmount,
      orderAmount,
    });

    // const invoiceAmount = helpers.recalcInvoiceAmount({
    //   ...values,
    //   ...newValue,
    // });
    // const totalExecuted = helpers.recalcTotalExecuted({
    //   ...values,
    //   ...newValue,
    //   invoiceAmount,
    // });
    // const orderAmount = helpers.recalcOrderAmount({
    //   ...values,
    //   ...newValue,
    // });
    // setValues({
    //   ...values,
    //   ...newValue,
    //   totalExecuted,
    //   invoiceAmount,
    //   orderAmount,
    // });
  };

  const onChangeChildSplits = (newValue) => {
    const newVersion = {
      ...childValues,
      ...newValue,
      pricePerPiece: values.pricePerPiece,
    };

    if (newValue.shipToQuantity >= values.balanceQty) {
      openNotificationWithIcon('warning');
    }

    const invoiceAmount = helpers.recalcInvoiceAmount({
      ...newVersion,
    });

    const totalExecuted = helpers.recalcTotalExecuted({
      ...newVersion,
      invoiceAmount,
    });

    setChildValues({
      ...newVersion,
      invoiceAmount,
      totalExecuted,
    });
  };

  if (entryById.loading) {
    return (
      <div style={{ fontSize: 20, color: '#000', textAlign: 'center' }}>
        <LoadingOutlined />
      </div>
    );
  }

  if (entryById.error) return 'error...';

  // will delete an entry
  const handleDelete = async () => {
    try {
      setDeleting(true);
      await deleteEntry({
        variables: {
          id: values.id,
        },
        refetchQueries,
      });
      message.success('Entry was successfully deleted');
      setDeleting(false);
      onCancel();
    } catch (err) {
      setDeleting(false);
      console.log(err);
    }
  };

  return (
    <div
      style={{
        width: 800,
        margin: 'auto',
        maxWidth: '100%',
        paddingBottom: 80,
        paddingTop: 8,
      }}
    >
      <div style={{ marginBottom: 0 }}>
        <LinkButton colorType='grey' onClick={onCancel}>
          <ArrowLeftOutlined style={{ marginRight: 4 }} /> Go Back
        </LinkButton>
      </div>
      {deleting && <Loading text='Deleting...' />}
      <EntryForm
        isNewEntry={false}
        values={values}
        childValues={childValues}
        setChildValues={setChildValues}
        setValues={setValues}
        onChangeChildSplits={onChangeChildSplits}
        onChange={onChange}
        errors={errors}
        onClearError={() => setErrors([])}
        loading={loading}
        onSubmit={onSubmit}
        handleDelete={handleDelete}
        onCancel={onCancel}
      />
    </div>
  );
}
