import React from 'react';
import styled from 'styled-components';
import Modal from 'components/common/Modal';
import Button from 'components/common/Button';
import FormItem from 'components/common/FormItem';
import Input from 'components/inputs/Input';
import Row from 'components/common/Row';
import Col from 'components/common/Col';
import DateInput from 'components/inputs/DateInput';
import message from 'components/common/message';
import helpers from 'lib/helpers';
import Popconfirm from 'components/common/Popconfirm';
import AntdNotification from 'components/common/notification';
// APOLLO
import { useMutation } from '@apollo/client';
import SAVE_SPLIT from 'ApolloClient/Mutations/saveSplit';
import ENTRY_BY_D from 'ApolloClient/Queries/entryById';
import DELETE_CHILD from 'ApolloClient/Mutations/deleteChildSplit';

const ViewParentBtn = styled.button`
  border: 0px;
  background: transparent;
  color: ${(p) => p.theme.colors.primary1};
  text-decoration: underline;
  &:hover {
    cursor: pointer;
    color: ${(p) => p.theme.colors.primary2};
  }
  &:focus {
    outline: 0;
  }
`;

const DeleteBtn = styled.button`
  border: 0px;
  background: transparent;
  color: ${(p) => p.theme.colors.red5};
  text-decoration: underline;
  &:hover {
    cursor: pointer;
    color: ${(p) => p.theme.colors.red3};
  }
  &:focus {
    outline: 0;
  }
`;

const SplitModal = ({
  handleCancel,
  modalOpen,
  values,
  childValues,
  onChangeChildSplits,
  isDollars,
  isValidMoney,
  getDateValue,
  Calculated,
  newSplit,
  onCancel,
}) => {
  const openNotificationWithIcon = (type) => {
    AntdNotification[type]({
      message: 'Split Order Complete',
      description:
        'This will be your final split for this entry and it’s status will be changed to “EXECUTED (with splits)',
    });
  };

  const [saveSplit] = useMutation(SAVE_SPLIT, {
    onCompleted: (data) => {
      if (data.saveSplit.balanceQty === 0) {
        openNotificationWithIcon('success');
      }
    },
  });
  const [deleteSplit] = useMutation(DELETE_CHILD);
  const [deleting, setDeleting] = React.useState(false);

  const handleDelete = async () => {
    try {
      setDeleting(true);
      await deleteSplit({
        variables: {
          parentId: values.id,
          id: childValues.id,
        },
        refetchQueries: [
          {
            query: ENTRY_BY_D,
            variables: {
              id: values.id,
            },
          },
        ],
      });
      message.success('Entry was successfully deleted');
      setDeleting(false);
      if (!deleting) {
        onCancel();
      }
    } catch (err) {
      setDeleting(false);
      console.log(err);
    }
  };

  const onChildSplitSave = async () => {
    if (childValues.shipToQuantity <= 0) {
      AntdNotification['error']({
        message: 'Ship To Quantity is required!',
      });
      return;
    }
    if (!childValues.executedDate) {
      AntdNotification['error']({
        message: 'Executed Date is required!',
      });
      return;
    }
    const totalExecuted =
      childValues.totalExecuted &&
      parseInt(
        childValues.totalExecuted.replace(/\./g, '').replace(/,/g, '') // remove all commas and periods from the string and turn it into an int/number (IE make it into cents)
      );
    let variables = {
      newSplit,
      id: values.id,
      params: {
        ...childValues,
        totalExecuted,
        executedDate: childValues.executedDate
          ? helpers.getTimezoneDate(childValues.executedDate)
          : null,
      },
    };
    delete variables.params.__typename;
    try {
      await saveSplit({
        variables,
        refetchQueries: [
          {
            query: ENTRY_BY_D,
            variables: {
              id: values.id,
            },
          },
        ],
      });
      message.success('Child entry was successfully saved!');
      onCancel();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Modal
      title={newSplit ? 'Create New Split' : 'Update Split Order'}
      visible={modalOpen}
      onOk={onChildSplitSave}
      onCancel={handleCancel}
      footer={[
        <Button key='save' onClick={onChildSplitSave} style={{ width: 175 }}>
          Save
        </Button>,
        <Button key='cancel' onClick={handleCancel} style={{ width: 175 }}>
          Cancel
        </Button>,
      ]}
    >
      <Row>
        <ViewParentBtn onClick={handleCancel}>View parent order</ViewParentBtn>
      </Row>
      <Row gutter={24}>
        <Col xs={12}>
          <FormItem label='Batch #'>
            <Input
              placeholder='Type here to create a new Batch Number...'
              value={childValues.batchTitle || ''}
              onChange={(e) =>
                onChangeChildSplits({
                  batchTitle: e.target.value,
                  newBatch: e.target.value,
                  batchId: null,
                })
              }
            />
          </FormItem>
          <FormItem label='Ship to Quantity' required>
            <Input
              type='number'
              value={childValues.shipToQuantity || '0'}
              onChange={(e) =>
                onChangeChildSplits({
                  shipToQuantity: parseInt(e.target.value),
                })
              }
            />
          </FormItem>
          <FormItem
            label='Air Freight Cost'
            error={
              childValues.airFreightCost &&
              !isDollars(childValues.airFreightCost)
                ? 'Air Freight Cost must be formatted as 0.00'
                : null
            }
          >
            <Input
              value={childValues.airFreightCost || '0.00'}
              onChange={(e) => {
                if (isValidMoney(e.target.value)) {
                  onChangeChildSplits({ airFreightCost: e.target.value });
                }
              }}
            />
          </FormItem>
          <FormItem
            label='Local Trucking Cost'
            error={
              childValues.localTruckingCost &&
              !isDollars(childValues.localTruckingCost)
                ? 'Local Trucking Cost must be formatted as 0.00'
                : null
            }
          >
            <Input
              value={childValues.localTruckingCost || '0.00'}
              onChange={(e) => {
                if (isValidMoney(e.target.value)) {
                  onChangeChildSplits({ localTruckingCost: e.target.value });
                }
              }}
            />
          </FormItem>
        </Col>
        <Col xs={12}>
          <FormItem
            label='Tariffs'
            error={
              childValues.tariffs && !isDollars(childValues.tariffs)
                ? 'Tariffs must be formatted as 0.00'
                : null
            }
          >
            <Input
              value={childValues.tariffs || '0.00'}
              onChange={(e) => {
                if (isValidMoney(e.target.value)) {
                  onChangeChildSplits({ tariffs: e.target.value });
                }
              }}
            />
          </FormItem>
          <FormItem
            label='Other Additional Charges'
            error={
              childValues.otherAdditionalCharges &&
              !isDollars(childValues.otherAdditionalCharges)
                ? 'Other additional charges must be formatted as 0.00'
                : null
            }
          >
            <Input
              value={childValues.otherAdditionalCharges || '0.00'}
              onChange={(e) => {
                if (isValidMoney(e.target.value)) {
                  onChangeChildSplits({
                    otherAdditionalCharges: e.target.value,
                  });
                }
              }}
            />
          </FormItem>
          <FormItem
            label='Discount'
            error={
              childValues.discount && !isDollars(childValues.discount)
                ? 'Discount must be formatted as 0.00'
                : null
            }
          >
            <Input
              value={childValues.discount || '0.00'}
              onChange={(e) => {
                if (isValidMoney(e.target.value)) {
                  onChangeChildSplits({ discount: e.target.value });
                }
              }}
            />
          </FormItem>
          <FormItem required label='Executed Date'>
            <DateInput
              required
              value={
                childValues.executedDate
                  ? getDateValue(childValues.executedDate)
                  : undefined
              }
              onChange={(newValue) =>
                onChangeChildSplits({ executedDate: newValue })
              }
            />
          </FormItem>
        </Col>
      </Row>
      <Row justify={'space-between'}>
        <Col xs={12}>
          <FormItem>
            <Calculated
              label='Invoice amount'
              value={childValues.invoiceAmount || ''}
            />
          </FormItem>
        </Col>
        <Col xs={12}>
          <FormItem>
            <Calculated
              label='Executed Split'
              value={childValues.totalExecuted}
            />
          </FormItem>
        </Col>
      </Row>
      {!newSplit && (
        <div style={{ marginTop: 16 }}>
          <Popconfirm
            title='Are you sure you want to delete this entry?'
            okText='Yes'
            onConfirm={handleDelete}
          >
            <DeleteBtn>Delete This Entry</DeleteBtn>
          </Popconfirm>
        </div>
      )}
    </Modal>
  );
};

export default SplitModal;
