import React, {useState, useEffect} from 'react';
import Select from 'components/inputs/SelectInput';
import DropdownStyleWrapper from 'components/inputs/DropdownStyleWrapper';
import {useQuery} from '@apollo/client';
import PURCHASE_ORDERS from 'ApolloClient/Queries/purchaseOrders';

const {Option} = Select;

export default function PoInput({
  value,
  placeholder = 'Select an existing PO',
  onChange,
  defaultSearch,
}) {
  const [searchText, setSearchText] = useState('');
  const {data, loading} = useQuery(PURCHASE_ORDERS, {
    variables: {
      searchText,
    },
  });

  useEffect(() => {
    setSearchText(defaultSearch);
  }, [defaultSearch]);

  return (
    <DropdownStyleWrapper>
      <Select
        value={value}
        onSearch={(value) => setSearchText(value)}
        showSearch
        placeholder={placeholder}
        style={{width: '100%'}}
        showArrow={false}
        filterOption={false}
        notFoundContent={loading ? 'searching...' : null}
        onChange={onChange}
      >
        {data &&
          data.purchaseOrders &&
          data.purchaseOrders.map((item) => {
            return <Option key={item.id}>{item.title}</Option>;
          })}
      </Select>
    </DropdownStyleWrapper>
  );
}

// import React from 'react';
// import Select from 'components/inputs/SelectInput';
// import DropdownStyleWrapper from 'components/inputs/DropdownStyleWrapper';
// // APOLLO
// import {useQuery} from '@apollo/client';
// import PURCHASE_ORDERS from 'ApolloClient/Queries/purchaseOrders';

// const {Option} = Select;

// export default ({value, onChange}) => {
//   const {data, loading, error} = useQuery(PURCHASE_ORDERS);

//   if (error) return 'error...';

//   return (
//     <DropdownStyleWrapper>
//       <Select
//         value={value}
//         placeholder="Select an existing PO"
//         style={{width: '100%'}}
//         onChange={onChange}
//       >
//         {loading && <Option value={null}>Loading...</Option>}
//         {data &&
//           data.purchaseOrders &&
//           data.purchaseOrders.map((item) => (
//             <Option key={item.id} value={item.id}>
//               {item.title}
//             </Option>
//           ))}
//       </Select>
//     </DropdownStyleWrapper>
//   );
// };
