import React from 'react';
// APOLLO
import REPORT_SALES_BY_CATEGORY from 'ApolloClient/Queries/reportSalesByCategory';
import { useQuery } from '@apollo/client';
// COMPONENTS
import Table from 'components/common/Table';
// LIB
import helpers from 'lib/helpers';

const COLUMNS = [
  {
    title: 'Category',
    key: '__typename',
    render: (record) => helpers.getCategoryLabelFromValue(record.category),
  },
  {
    title: 'TOTAL',
    render: (record) => helpers.centsToDollars(record.total),
  },
];

export default function SalesByCategoryReport({ startDate, endDate }) {
  const { data, loading, error } = useQuery(REPORT_SALES_BY_CATEGORY, {
    variables: {
      startDate,
      endDate,
    },
    fetchPolicy: 'network-only',
    // skip: !endDate || !startDate,
  });
  if (loading) return 'loading...';
  if (error) return 'error...';
  return (
    <Table
      columns={COLUMNS}
      dataSource={data.reportSalesByCategory}
      rowKey='category'
    />
  );
}
