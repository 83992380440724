import React from 'react';
import Select from 'components/inputs/SelectInput';
import DropdownStyleWrapper from 'components/inputs/DropdownStyleWrapper';

const { Option } = Select;

export default function PoStatusInput({ value, onChange }) {
  return (
    <DropdownStyleWrapper>
      <Select
        value={value}
        placeholder='PO Status'
        style={{ width: '100%' }}
        onChange={onChange}
      >
        <Option value='ACTIVE'>ACTIVE</Option>
        <Option value='CANCELLED'>CANCELLED</Option>
        <Option value='COMPLETED'>COMPLETED</Option>
      </Select>
    </DropdownStyleWrapper>
  );
}
