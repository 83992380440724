import {gql} from '@apollo/client';

export default gql`
  query reportOrders($startDate: String, $endDate: String) {
    reportOrders(startDate: $startDate, endDate: $endDate) {
      underExecution
      executed
      total
    }
  }
`;
