import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import EntryForm from 'components/common/EntryForm';
import notification from 'components/common/notification';
// LIB
import useOnSaveEntry from 'lib/hooks/useOnSaveEntry';
import helpers from 'lib/helpers';
import moment from 'moment';

const DEFAULT_OPTIONS = {
  orderStatus: 'underExecution',
  shipToQuantity: 0,
  orderQuantity: 0,
  airFreightCost: '0.00',
  airFreightCostPerPiece: '0.00',
  localTruckingCost: '0.00',
  orderDate: moment(),
  tariffs: '0.00',
  otherAdditionalCharges: '0.00',
  discount: '0.00',
  poStatus: 'ACTIVE',
};

export default function AdminHome() {
  const history = useHistory();
  const [values, setValues] = useState(DEFAULT_OPTIONS);
  const [childValues, setChildValues] = useState({});
  const [isSplit, setIsSplit] = useState(false);

  const onAfterSubmit = (entryId) => {
    setValues(DEFAULT_OPTIONS);
    document.body.scrollTop = 0;
    notification.success({
      message: 'Success',
      description: 'Your entry was successfully added to the database.',
    });
    history.push(`/admin/search?selectedId=${entryId}`);
  };

  const { errors, loading, onSubmit, setErrors } = useOnSaveEntry({
    values,
    onAfterSubmit,
  });

  const onChange = (newValue) => {
    const newVersion = {
      ...values,
      ...newValue,
    };

    const invoiceAmount = helpers.recalcInvoiceAmount({
      ...newVersion,
    });

    let totalExecuted = newVersion.totalExecuted;

    // we only want to re-calculate total executed if the orderStatus is executed
    if (newVersion.orderStatus === 'executed') {
      totalExecuted = helpers.recalcTotalExecuted({
        ...newVersion,
        invoiceAmount,
      });
    }

    const orderAmount = helpers.recalcOrderAmount({
      ...newVersion,
    });

    setValues({
      ...newVersion,
      totalExecuted,
      invoiceAmount,
      orderAmount,
    });
  };

  return (
    <div
      style={{
        width: 800,
        margin: 'auto',
        maxWidth: '100%',
        paddingBottom: 80,
        paddingTop: 40,
      }}
    >
      <EntryForm
        isNewEntry // tells the form we're creating a new item not editing an existing record
        childValues={childValues}
        setChildValues={setChildValues}
        values={values}
        setValues={setValues}
        isSplit={isSplit}
        setIsSplit={setIsSplit}
        onSubmit={onSubmit}
        onClearError={() => setErrors([])}
        errors={errors}
        loading={loading}
        onChange={onChange}
      />
    </div>
  );
}
