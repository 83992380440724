// TOP LEVEL IMPORTS
import React from 'react';
import {BrowserRouter as Router, Switch} from 'react-router-dom';
// PUBLIC
import PublicRoute from 'components/route-components/PublicRoute';
import AdminRoute from 'components/route-components/AdminRoute';
// LAYOUTS
import PublicLayout from 'components/layouts/PublicLayout';
import AdminLayout from 'components/layouts/AdminLayout';
// COMPONENTS
import Loading from 'components/common/Loading';
// AUTH
import AuthLoginRoute from 'routes/auth-login';
// ADMIN
import AdminHomeRoute from 'routes/admin-home';
import AdminDashboardRoute from 'routes/admin-dashboard';
import AdminReportsRoute from 'routes/admin-reports';
import AdminSearchRoute from 'routes/admin-search';
import AdminVendorsRoute from 'routes/admin-vendors';
import AdminCustomersRoute from 'routes/admin-customers';
// APOLLO
import {useQuery} from '@apollo/client';
import CURRENT_USER from 'ApolloClient/Queries/currentUser';

export default function AppRoutes() {
  const {data, loading, error} = useQuery(CURRENT_USER, {
    errorPolicy: 'all',
    pollInterval: 200000, // rerun currentUser every X milliseconds
  });

  let errorExists = error && error.message;

  if (loading) {
    return <Loading />;
  }

  let currentUser = data && data.currentUser;

  // all other errors (that are not in our white listed errors list) just print to screen for now, unless it's a white listed error
  if (errorExists) {
    return (
      <div>
        Something went wrong... If this continues please contact support.
      </div>
    );
  }

  return (
    <Router>
      <Switch>
        <PublicRoute
          exact
          layout={PublicLayout}
          path="/"
          currentUser={currentUser}
          component={AuthLoginRoute}
        />
        <AdminRoute
          exact
          layout={AdminLayout}
          path="/admin/dashboard"
          currentUser={currentUser}
          component={AdminDashboardRoute}
        />
        <AdminRoute
          exact
          layout={AdminLayout}
          path="/admin/reports"
          currentUser={currentUser}
          component={AdminReportsRoute}
        />
        <AdminRoute
          exact
          layout={AdminLayout}
          path="/admin/search"
          currentUser={currentUser}
          component={AdminSearchRoute}
        />
        <AdminRoute
          exact
          layout={AdminLayout}
          path="/admin/vendors"
          currentUser={currentUser}
          component={AdminVendorsRoute}
        />
        <AdminRoute
          exact
          layout={AdminLayout}
          path="/admin/customers"
          currentUser={currentUser}
          component={AdminCustomersRoute}
        />
        <AdminRoute
          exact
          layout={AdminLayout}
          path="/admin"
          currentUser={currentUser}
          component={AdminHomeRoute}
        />
      </Switch>
    </Router>
  );
}
