import { useState } from 'react';
// APOLLO
import { useMutation } from '@apollo/client';
import SAVE_ENTRY from 'ApolloClient/Mutations/saveEntry';
//
import helpers from 'lib/helpers';
import _ from 'lodash';

const isMoreThan4Dec = (value) => {
  if (!value) return false;
  if (value.length === 0) return false;
  if (!value.includes('.')) return false;
  let index = value.indexOf('.');
  if (value.length - index > 5) {
    return true; // return true if it is more than 4 decimal places
  }
  return false;
};

const isLessThanFiveDollars = (val) => {
  if (!val) return null;
  if (val.length === 0) return null;
  if (parseFloat(val) > 5) {
    return 'Cannot exceed more than $5 since it is per piece price';
  }
  if (!val.includes('.')) return null;
  let index = val.indexOf('.');
  if (val.length - index > 5) {
    return 'This value can NOT have more than 4 decimal places';
  }
  return null;
};

const isMoreThanOtherField = (values) => {
  if (!values.cost) return null;
  if (values.cost.length === 0) return null;
  if (parseFloat(values.cost) > parseFloat(values.pricePerPiece)) {
    return 'Cost per Piece cannot be higher than Price per Piece!';
  }
  if (!values.cost.includes('.')) return null;
  let index = values.cost.indexOf('.');
  if (values.cost.length - index > 5) {
    return 'This value can NOT have more than 4 decimal places';
  }
  return null;
};

const handleCustomerInput = (values) => {
  // If no customer type selected, no errors.
  if (!values.customerType || !values.customerId || !values.endCustomerId) {
    return null;
  }
  if (
    values.customerId === values.endCustomerId &&
    values.customerType === 'distributor'
  ) {
    // Customer and End Customer cannot be the same when distributor is selected.
    return 'Customer & End Customer must be different';
  }
  if (
    values.customerId !== values.endCustomerId &&
    values.customerType !== 'distributor'
  ) {
    // Customer and End Customer cannot be the same when distributor is selected.
    return 'Customer & End Customer must be the same';
  }
  return null;
};

const useOnSaveEntry = ({ id, values, onAfterSubmit, refetchQueries = [] }) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [saveEntry] = useMutation(SAVE_ENTRY);

  const onSubmit = async () => {
    try {
      // reset errors
      setErrors([]);
      let params = {
        ...values,
      };

      // delete any values that we can not pass to the mutation
      _.unset(params, '__typename');
      _.unset(params, 'childSplits');
      delete params.id;
      delete params.createdAt;
      delete params.purchaseOrder;
      delete params.batch;
      delete params.vendor;
      delete params.customer;
      delete params.endCustomer;

      // Do some validation
      if (!params.itemId || params.itemId.length === 0) {
        return setErrors(['Item ID is required']);
      }

      if (!params.description || params.description.length === 0) {
        return setErrors(['Description is required']);
      }

      if (!params.poId && !params.newPO) {
        return setErrors(['PO is required']);
      }

      if (!params.batchId && !params.newBatch) {
        return setErrors(['batch # is required is required']);
      }

      if (!params.customerId) {
        return setErrors(['Customer is required']);
      }

      if (!params.endCustomerId) {
        return setErrors(['End Customer is required']);
      }

      if (!params.customerType) {
        return setErrors(['Customer Type is required']);
      }

      if (params.customerType && handleCustomerInput(params)) {
        return setErrors([
          'Please address form errors with Customer, Customer Type, and End Customer',
        ]);
      }

      if (!params.vendorId) {
        return setErrors(['Vendor is required']);
      }

      if (!params.orderType) {
        return setErrors(['Order type is required']);
      }

      if (!params.orderStatus) {
        return setErrors(['Order status is required']);
      }

      if (!params.orderCategory) {
        return setErrors(['Order category is required']);
      }

      if (!params.orderAmount || params.orderAmount.length === 0) {
        return setErrors(['Order amount is required']);
      }

      if (params.orderAmount[params.orderAmount.length - 3] !== '.') {
        return setErrors(['Order amount must be formatted as 0.00']);
      }

      if (!params.orderDate) {
        return setErrors(['Order date is required']);
      }

      if (!params.dueDate) {
        return setErrors(['Due date is required']);
      }

      if (!params.orderQuantity) {
        return setErrors(['Order quantity is required']);
      }

      if (!params.pricePerPiece || params.pricePerPiece.length === 0) {
        return setErrors(['Price per piece is required']);
      }

      if (isMoreThan4Dec(params.pricePerPiece)) {
        return setErrors([
          'Price per piece can not have more than 4 decimal places',
        ]);
      }

      if (params.orderStatus === 'executed') {
        delete params.childSplits;
      }

      if (params.orderStatus === 'executed' && !params.invoiceAmount) {
        return setErrors([
          'You must provide an invoice amount for an order with status of executed.',
        ]);
      }

      if (
        params.orderStatus === 'executed' &&
        isMoreThan4Dec(params.invoiceAmount)
      ) {
        return setErrors([
          'You invoice amount can not have more than 4 decimal places',
        ]);
      }

      if (params.cost && isMoreThan4Dec(params.cost)) {
        return setErrors([
          'You cost amount can not have more than 4 decimal places',
        ]);
      }

      if (params.airFreightCost > 0 && params.airFreightCostPerPiece <= 0) {
        return setErrors([
          'Air Freight Cost cannot be empty if Air Freight Price has a value in it',
        ]);
      }

      if (
        params.airFreightCostPerPiece &&
        isLessThanFiveDollars(params.airFreightCostPerPiece)
      ) {
        return setErrors([
          isLessThanFiveDollars(params.airFreightCostPerPiece),
        ]);
      }

      if (params.cost && isMoreThanOtherField(params)) {
        return setErrors([isMoreThanOtherField(params)]);
      }

      if (params.moldCost && isMoreThan4Dec(params.moldCost)) {
        return setErrors(['Mold Cost can not have more than 4 decimal places']);
      }

      if (params.sampleCost && isMoreThan4Dec(params.sampleCost)) {
        return setErrors([
          'Sample Cost can not have more than 4 decimal places',
        ]);
      }

      if (params.otherCost && isMoreThan4Dec(params.otherCost)) {
        return setErrors([
          'Other Cost can not have more than 4 decimal places',
        ]);
      }

      const orderAmount = parseInt(
        params.orderAmount.replace(/\./g, '').replace(/,/g, '') // remove all commas and periods from the string and turn it into an int/number (IE make it into cents)
      );

      const totalExecuted =
        params.totalExecuted &&
        parseInt(
          params.totalExecuted.replace(/\./g, '').replace(/,/g, '') // remove all commas and periods from the string and turn it into an int/number (IE make it into cents)
        );

      // create variables object
      const variables = {
        params: {
          ...params,
          orderAmount,
          totalExecuted,
          executedDate: params.executedDate
            ? helpers.getTimezoneDate(params.executedDate)
            : null,
          dueDate: helpers.getTimezoneDate(params.dueDate), // params.dueDate.startOf('day').valueOf().toString(),
          orderDate: helpers.getTimezoneDate(params.orderDate), // params.orderDate.startOf('day').valueOf().toString(),
          paymentDate: params.paymentDate
            ? helpers.getTimezoneDate(params.paymentDate)
            : null,
          etdChina: params.etdChina
            ? helpers.getTimezoneDate(params.etdChina)
            : null,
          etdLaPort: params.etdLaPort
            ? helpers.getTimezoneDate(params.etdLaPort)
            : null,
        },
      };

      console.log(variables);
      // if this is an update rather than a create, we will add the id to the variables before calling the mutation
      if (id) {
        variables.id = id;
      }
      // set the loading
      setLoading(true);

      // call mutation
      let result = await saveEntry({
        variables,
        refetchQueries,
      });

      setLoading(false);

      // if a call back was passed, call it
      if (onAfterSubmit) {
        onAfterSubmit(result.data.saveEntry.id);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  return {
    errors,
    onSubmit,
    loading,
    setErrors,
  };
};

export default useOnSaveEntry;
