import {gql} from '@apollo/client';

export default gql`
  query customers($searchText: String, $skip: Int, $limit: Int) {
    customers(searchText: $searchText, skip: $skip, limit: $limit) {
      id
      title
    }
  }
`;
