import constants from 'lib/constants';
import moment from 'moment-timezone';

export const ORDER_CATEGORIES = [
  {
    value: 'airlessBottles',
    label: 'AIRLESS BOTTLES',
  },
  {
    value: 'airlessTubes',
    label: 'AIRLESS TUBES',
  },
  {
    value: 'aluminumBottles',
    label: 'ALUMINUM BOTTLES',
  },
  {
    value: 'aluminumTubes',
    label: 'ALUMINUM TUBES',
  },
  {
    value: 'secondaryBoxes',
    label: 'SECONDARY BOXES',
  },
  {
    value: 'cosmeticComponents',
    label: 'COSMETIC COMPONENTS',
  },
  {
    value: 'plasticTubes',
    label: 'PLASTIC TUBES',
  },
  {
    value: 'laminateTubes',
    label: 'LAMINATE TUBES',
  },
  {
    value: 'glassBottles',
    label: 'GLASS BOTTLES',
  },
  {
    value: 'bottles',
    label: 'BOTTLES',
  },
  {
    value: 'jars',
    label: 'JARS',
  },
  {
    value: 'rollerBallApplication',
    label: 'ROLLER BALL APPLICATOR',
  },
  {
    value: 'other',
    label: 'OTHER',
  },
];

const cleanValue = (value) => {
  if (!value) return 0;
  if (value && typeof value === 'string') {
    return parseFloat(value.replace(/[^\d.]/g, '').replace(/,/g, ''));
  }
  return value;
};

const helpers = {
  getCategoryLabelFromValue: (value) => {
    return ORDER_CATEGORIES.filter((item) => item.value === value)[0].label;
  },
  getTypeLabelFromValue: (value) => {
    if (value === 'repeatOrder') return 'REPEAT ORDER';
    if (value === 'newOrder') return 'NEW ORDER';
    if (value === 'samples') return 'SAMPLES';
    if (value === 'molds') return 'MOLDS';
    if (value === 'tod') return 'TOD';
  },
  getStatusLabel: (value) => {
    switch (value) {
      case 'underExecution':
        return 'UNDER EXECUTION';
      case 'partiallyExecuted':
        return 'PARTIALLY UNDER EXECUTION';
      case 'executed':
        return 'EXECUTED';
      case 'executedWithSplits':
        return 'EXECUTED WITH SPLITS';
      default:
        return null;
    }
  },
  /**
   *
   * getTimezoneDate
   * if somebody fills out the date picker with 1/21/21, we want to make sure the timestamp that we store is 12PM 1/21/21 CALIFORNIA TIME ZONE.
   * This funciton may take a timestamp NUMBER, timestamp STRING or potentially another date that can be fed directly into moment without parseInt
   */
  getTimezoneDate: (date) => {
    let formattedDayMonth;
    const format = 'MM/DD/YYYY';

    if (typeof date === 'number' || typeof date === 'string') {
      formattedDayMonth = moment(parseInt(date)).format(format);
    } else {
      formattedDayMonth = moment(date).format(format);
    }

    return moment(formattedDayMonth, format)
      .tz(constants.timezone)
      .startOf('day')
      .valueOf()
      .toString();

    // if (typeof date === 'number' || typeof date === 'string') {
    //   return moment(parseInt(date))
    //     .tz(constants.timezone)
    //     .startOf('day')
    //     .valueOf()
    //     .toString();
    // }
    // return moment(date)
    //   .tz(constants.timezone)
    //   .startOf('day')
    //   .valueOf()
    //   .toString();
  },
  recalcTotalExecuted: ({
    airFreightCost = 0,
    localTruckingCost = 0,
    tariffs = 0,
    otherAdditionalCharges = 0,
    invoiceAmount = 0,
    discount = 0,
  }) => {
    const invoice = cleanValue(invoiceAmount);
    const air = cleanValue(airFreightCost);
    const tariff = cleanValue(tariffs);
    const otherAdditionalCharge = cleanValue(otherAdditionalCharges);
    const trucking = cleanValue(localTruckingCost);
    const discountInt = cleanValue(discount);

    return (
      invoice +
      air +
      trucking +
      tariff +
      otherAdditionalCharge -
      discountInt
    ).toFixed(2);
  },
  recalcOrderAmount: ({ pricePerPiece = 0, orderQuantity = 0 }) => {
    return (cleanValue(orderQuantity) * cleanValue(pricePerPiece)).toFixed(2);
  },
  recalcInvoiceAmount: ({ shipToQuantity = 0, pricePerPiece = 0 }) => {
    return (cleanValue(shipToQuantity) * cleanValue(pricePerPiece)).toFixed(2);
  },
  centsToDollars: (value) => {
    return (value / 100).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });
  },
};

export default helpers;
