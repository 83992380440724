import React from 'react';
// APOLLO
import REPORT_SALES_BY_TOP_5 from 'ApolloClient/Queries/reportSalesByTop5';
import {useQuery} from '@apollo/client';
// COMPONENTS
import Table from 'components/common/Table';
// LIB
import helpers from 'lib/helpers';

const COLUMNS = [
  {
    title: 'CUSTOMER',
    key: '__typename',
    render: (record) => record.customer,
  },
  // {
  //   title: 'UNDER EXECUTION',
  //   render: (record) => helpers.centsToDollars(record.underExecution),
  // },
  {
    title: 'NEW SALES',
    render: (record) => helpers.centsToDollars(record.newSales),
  },
  // {
  //   title: 'EXECUTED',
  //   render: (record) => helpers.centsToDollars(record.executed),
  // },
  // {
  //   title: 'TOTAL',
  //   render: (record) => helpers.centsToDollars(record.total),
  // },
];

export default function SalesByTop5Report({startDate, endDate}) {
  const {data, loading, error} = useQuery(REPORT_SALES_BY_TOP_5, {
    variables: {
      startDate,
      endDate,
    },
    fetchPolicy: 'network-only',
    skip: !endDate || !startDate,
  });
  if (loading) return 'loading...';
  if (error) return 'error...';

  return (
    <Table
      columns={COLUMNS}
      dataSource={data.reportSalesByTop5}
      rowKey="customer"
    />
  );
}
