import styled from 'styled-components';

export default styled.button`
  border: 0px;
  background: transparent;
  color: ${(p) => {
    if (p.colorType === 'grey') {
      return p.theme.colors.neutral7;
    }
    return p.theme.colors.primary;
  }};
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
  &:focus {
    outline: 0;
  }
`;
