const constants = {
  appName: 'Global Packaging Inc',
  authTokenName: 'bizpack_access_token',
  refreshTokenName: 'bizpack_refresh_token',
  supportEmail: 'example@example.com',
  mobileBreakpoint: 1025,
  timezone: 'America/Los_Angeles',
};

export default constants;
