import React from 'react';
import Select from 'components/inputs/SelectInput';
import DropdownStyleWrapper from 'components/inputs/DropdownStyleWrapper';

const {Option} = Select;

export default function SearchByInput({value, onChange}) {
  return (
    <DropdownStyleWrapper>
      <Select
        value={value}
        placeholder="Search  by"
        style={{width: 220, marginLeft: 16, marginTop: 16}}
        onChange={onChange}
      >
        <Option value="batch">Batch</Option>
        <Option value="po">PO</Option>
        <Option value="itemId">Item #</Option>
      </Select>
    </DropdownStyleWrapper>
  );
}
