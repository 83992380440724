import {gql} from '@apollo/client';

export default gql`
  query currentUser {
    currentUser {
      id
      roles
      email
    }
  }
`;
