import {gql} from '@apollo/client';

export default gql`
  query reportSalesByCategory($startDate: String, $endDate: String) {
    reportSalesByCategory(startDate: $startDate, endDate: $endDate) {
      id
      category
      underExecution
      executed
      total
    }
  }
`;
