import styled from 'styled-components';

export default styled.input`
  height: 50px;
  border-radius: 5px;
  background: ${(p) => p.theme.colors.fieldBackground};
  color: ${(p) => p.theme.colors.fieldText};
  width: 100%;
  border: 0px;
  padding-left: 8px;
  &:focus {
    outline: 0;
    background: ${(p) => p.theme.colors.activeFieldBackground};
    color: ${(p) => p.theme.colors.activeFieldText};
  }
  ::placeholder {
    color: ${(p) => p.theme.colors.placeholder};
  }

  &:disabled {
    cursor: not-allowed;
    background: ${(p) => p.theme.colors.neutral10};
    color: ${(p) => p.theme.colors.neutral5};
  }
`;
