import React from 'react';
import moment from 'moment';
// APOLLO
import REPORT_SALES_BY_MONTH from 'ApolloClient/Queries/reportSalesByMonth';
import {useQuery} from '@apollo/client';
// COMPONENTS
import Table from 'components/common/Table';
import helpers from 'lib/helpers';

const COLUMNS = [
  {
    title: 'Month',
    key: '__typename',
    render: (record) => `${record._id.month}/${record._id.year}`,
  },
  {
    title: 'TOTAL',
    render: (record) => helpers.centsToDollars(record.total),
  },
];

export default function SalesByMonthReport({startDate, endDate}) {
  const {data, loading, error} = useQuery(REPORT_SALES_BY_MONTH, {
    variables: {
      startDate: moment(parseInt(startDate))
        .startOf('month')
        .valueOf()
        .toString(),
      endDate: moment(parseInt(endDate)).endOf('month').valueOf().toString(),
    },
    skip: !endDate || !startDate,
  });
  if (loading) return 'loading...';
  if (error) return 'error...';

  return (
    <>
      <Table
        columns={COLUMNS}
        dataSource={data.reportSalesByMonth}
        rowKey={(record) => `${record._id.month}/${record._id.year}`}
        pagination={{
          pageSize: 200,
        }}
      />
    </>
  );
}
