import {gql} from '@apollo/client';

export default gql`
  query batches($searchText: String) {
    batches(searchText: $searchText) {
      id
      title
    }
  }
`;
