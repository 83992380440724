import {createGlobalStyle} from 'styled-components';
import theme from './theme';

export const GlobalStyle = createGlobalStyle`
  /* TOP LEVEL CSS */
  div, a, h1, h2, h3, h4, h5, p, span {
    font-family: ${theme.fontFamily};
    font-size: 16px;
  }
  html, body {
    margin: 0px;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
  }

  #root {
    height: 100%;
    width: 100vw;
  }

  .ant-btn-primary {
    background: ${theme.colors.primary} !important;
    border-color:  ${theme.colors.primary} !important;
  }


`;

export default theme;
