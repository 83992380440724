import React from 'react';
import DatePicker from 'antd/lib/date-picker';
import 'antd/lib/date-picker/style/css';
import styled from 'styled-components';

const {RangePicker: AntdRangePicker} = DatePicker;

const Wrapper = styled.div`
  .ant-picker {
    height: 0px;
    background: ${(p) => p.theme.colors.primary4} !important;
    border: 0px;
    border-radius: 5px;
    .ant-picker-input input {
      font-size: 16px;
      color: ${(p) => p.theme.colors.fieldText};
      ::placeholder {
        color: ${(p) => p.theme.colors.primary3};
      }
    }
  }
`;

export default function RangePicker(props) {
  return (
    <Wrapper>
      <AntdRangePicker {...props} />
    </Wrapper>
  );
}
