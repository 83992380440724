import React, {useState, useEffect} from 'react';
import Select from 'components/inputs/SelectInput';
import DropdownStyleWrapper from 'components/inputs/DropdownStyleWrapper';
import {useQuery} from '@apollo/client';
import CUSTOMERS from 'ApolloClient/Queries/customers';

const {Option} = Select;

export default function CustomerInput({value, onChange, defaultSearch}) {
  const [searchText, setSearchText] = useState(defaultSearch || '');
  const {data, loading} = useQuery(CUSTOMERS, {
    variables: {
      searchText,
    },
  });

  useEffect(() => {
    setSearchText(defaultSearch);
  }, [defaultSearch]);

  return (
    <DropdownStyleWrapper>
      <Select
        value={value}
        onSearch={(value) => setSearchText(value)}
        showSearch
        placeholder="Customer"
        style={{width: '100%'}}
        showArrow={false}
        filterOption={false}
        notFoundContent={loading ? 'searching...' : null}
        onChange={onChange}
      >
        {data &&
          data.customers &&
          data.customers.map((item) => {
            return <Option key={item.id}>{item.title}</Option>;
          })}
      </Select>
    </DropdownStyleWrapper>
  );
}
