import React, { useState } from 'react';
import Modal from 'components/common/Modal';
import Button from 'components/common/Button';
import CHANGE_ORDER_STATUS from 'ApolloClient/Mutations/changeOrderStatus';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';

const ExecutedSplitModal = ({ executedModal, setExecutedModal, values }) => {
  const [balanceText, setBalanceText] = useState(false);
  const [changeOrderStatus] = useMutation(CHANGE_ORDER_STATUS);
  const history = useHistory();

  const handleClose = () => {
    setExecutedModal(false);
    setBalanceText(false);
  };

  const handleChangeOrderStatus = async (balanceQty) => {
    try {
      await changeOrderStatus({
        variables: {
          id: values.id,
          params: {
            orderStatus: 'executedWithSplits',
            balanceQty: balanceQty,
          },
        },
      });
      handleClose();
      return history.push('/admin/search');
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Modal
      title={balanceText ? 'Balance Quantity Reset' : 'Balance Remaining'}
      visible={executedModal}
      footer={[
        <Button
          key='save'
          style={{ width: 175 }}
          onClick={() => {
            if (!balanceText) {
              setBalanceText(true);
            } else {
              handleChangeOrderStatus(0);
            }
          }}
        >
          {balanceText ? 'Yes' : 'Save'}
        </Button>,
        <Button
          key='cancel'
          onClick={() => {
            if (!balanceText) {
              handleClose();
            } else {
              handleChangeOrderStatus(values.balanceQty);
            }
          }}
          style={{ width: 175 }}
        >
          {balanceText ? 'No' : 'Cancel'}
        </Button>,
      ]}
    >
      {balanceText
        ? 'Would you like to set the Balance Quantity to 0?'
        : `There is still a balance of ${values.balanceQty} remaining on this order, are you sure you want to change the status to Executed With Splits?`}
    </Modal>
  );
};

export default ExecutedSplitModal;
