import React, {useState} from 'react';
import Button from 'components/common/Button';
import ErrorBlock from 'components/common/ErrorBlock';
import Input from 'components/inputs/Input';

export default function SimpleForm({
  placeholder,
  defaultValue,
  errors,
  onSubmit,
  saving,
}) {
  const [value, setValue] = useState(defaultValue || '');
  const handleOnSubmit = (e) => {
    e && e.preventDefault();
    if (!value || value.length === 0) {
      return;
    }
    onSubmit(value);
  };
  return (
    <form onSubmit={handleOnSubmit} style={{marginTop: 32}}>
      <Input
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder={placeholder}
        style={{width: 450}}
      />
      <div>
        <Button
          disabled={saving}
          onSubmit={handleOnSubmit}
          type="submit"
          style={{width: 200, marginTop: 16}}
        >
          {!saving ? 'Save Changes' : '...'}
        </Button>
      </div>
      <div>{errors && errors.length > 0 && <ErrorBlock errors={errors} />}</div>
    </form>
  );
}
