import React from 'react';
import styled from 'styled-components';
import moment, { isMoment } from 'moment';
// COMPONENTS
import Row from 'components/common/Row';
import Col from 'components/common/Col';
import FormItem from 'components/common/FormItem';
import FormLabel from 'components/common/FormItem/FormLabel';
import Button from 'components/common/Button';
import { CloseCircleFilled, CloseOutlined } from '@ant-design/icons';
import Popconfirm from 'components/common/Popconfirm';
import SplitModal from './SplitModal';
import ExecutedSplitModal from './ExecutedSplitModal';
import Tabs from 'components/common/Tabs';
import Input from 'components/inputs/Input';
import TextAreaInput from 'components/inputs/TextAreaInput';
import DateInput from 'components/inputs/DateInput';
import OrderStatusInput from './OrderStatusInput';
import CustomerInput from './CustomerInput';
import VendorInput from './VendorInput';
import PoInput from './PoInput';
import BatchesInput from './BatchesInput';
import OrderCategory from './OrderCategory';
import OrderTypeInput from './OrderTypeInput';
import CustomerTypeInput from './CustomerTypeInput';
import PaymentTypeInput from './PaymentTypeInput';
import PoStatusInput from './PoStatusInput';
// APOLLO

const { TabPane } = Tabs;

const Value = styled.p`
  font-weight: 600;
  font-size: 18px;
  color: ${(p) => p.theme.colors.primary};
`;

const Break = styled.div`
  margin-top: 24px;
  background: ${(p) => p.theme.colors.neutral9};
  height: 2px;
`;

const DeleteBtn = styled.button`
  border: 0px;
  background: transparent;
  color: ${(p) => p.theme.colors.red5};
  text-decoration: underline;
  &:hover {
    cursor: pointer;
    color: ${(p) => p.theme.colors.red3};
  }
  &:focus {
    outline: 0;
  }
`;

const ChildSplitBtn = styled.button`
  border: 0px;
  background: transparent;
  color: ${(p) => p.theme.colors.primary3};
  text-decoration: underline;
  padding-top: 10px;
  &:hover {
    cursor: pointer;
    color: ${(p) => p.theme.colors.primary1};
  }
  &:focus {
    outline: 0;
  }
`;

const ErrorArea = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  left: 0px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: ${(p) => p.theme.colors.red8};
  padding-left: 8px;
  z-index: 10;
  color: ${(p) => p.theme.colors.red1};
`;

const Red = styled.span`
  color: ${(p) => p.theme.colors.red4};
`;

const ErrorText = styled.h1`
  font-size: 20px;
  margin: 0px;
  color: ${(p) => p.theme.colors.red2};
`;

const isDollars = (value) => {
  return value && value[value.length - 3] === '.';
};

const ButtonArea = ({
  isNewEntry,
  loading,
  onSubmit,
  handleDelete,
  orderStatus,
  showModal,
  showExecutedModal,
  values,
}) => {
  return (
    <>
      {isNewEntry && (
        <FormItem>
          <Button disabled={loading} onClick={onSubmit} style={{ width: 175 }}>
            {!loading ? 'New Entry' : '...'}
          </Button>
        </FormItem>
      )}
      {orderStatus === 'partiallyExecuted' && (
        <FormItem>
          <Button
            disabled={loading}
            onClick={showModal}
            style={{ width: 175 }}
            secondary
          >
            {!loading ? 'Create Split' : '...'}
          </Button>
        </FormItem>
      )}
      {!isNewEntry && (
        <FormItem>
          <Button
            disabled={loading}
            onClick={() => {
              if (
                orderStatus === 'executedWithSplits' &&
                values.balanceQty >= 1
              ) {
                return showExecutedModal();
              } else {
                return onSubmit();
              }
            }}
            style={{ width: 175 }}
          >
            {!loading ? 'Save Changes' : '...'}
          </Button>
        </FormItem>
      )}
      {!isNewEntry && (
        <div style={{ marginTop: 16 }}>
          <Popconfirm
            title='Are you sure you want to delete this entry?'
            okText='Yes'
            onConfirm={handleDelete}
          >
            <DeleteBtn>Delete This Entry</DeleteBtn>
          </Popconfirm>
        </div>
      )}
    </>
  );
};

const CalculatedArea = styled.div``;

const CalcValue = styled.h3`
  margin: 0px;
  font-size: 26px;
  color: ${(p) => p.theme.colors.primary1};
  font-weight: 600;
`;

function numberWithCommas(x) {
  return x && x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

const Underline = styled.div`
  height: 2px;
  background: ${(p) => p.theme.colors.primary3};
`;

const TabsWrapper = styled.div`
  margin-top: 16px;
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #22a8c5;
  }
  .ant-tabs {
    color: #989999;
  }
  .ant-tabs-tab:hover {
    color: #41727e;
  }
  .ant-tabs-tab {
    height: 60px;
    width: 120px;
    display: flex;
    justify-content: center;
  }
`;

const Calculated = ({ label, value }) => {
  return (
    <CalculatedArea>
      <div>{label}</div>
      <CalcValue>${numberWithCommas(value) || '0.00'}</CalcValue>
      <Underline />
    </CalculatedArea>
  );
};

const NoChangeField = ({ label, value }) => {
  return (
    <CalculatedArea>
      <div>{label}</div>
      <CalcValue>{value}</CalcValue>
      <Underline />
    </CalculatedArea>
  );
};

const DEFAULT_CHILD_OPTIONS = {
  shipToQuantity: 0,
  airFreightCost: '0.00',
  localTruckingCost: '0.00',
  tariffs: '0.00',
  otherAdditionalCharges: '0.00',
  discount: '0.00',
  executedDate: null,
  invoiceAmount: '0.00',
  totalExecuted: '0.00',
};

export default function EntryForm({
  isNewEntry,
  loading,
  errors,
  values,
  setValues,
  onChange,
  onSubmit,
  handleDelete,
  isSplit,
  setIsSplit,
  childValues,
  setChildValues,
  onChangeChildSplits,
  onCancel,
  onClearError = () => {},
}) {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [newSplit, setNewSplit] = React.useState(false);
  const [executedModal, setExecutedModal] = React.useState(false);
  const showExecutedModal = () => setExecutedModal(true);

  const showModal = () => {
    setModalOpen(true);
    setNewSplit(true);
    setChildValues({
      ...DEFAULT_CHILD_OPTIONS,
      batchId: values.batchId || null,
      newBatch: values.newBatch || null,
      batchTitle: values.batch.title || null,
    });
  };

  const showEditChildModal = (e, cv) => {
    e.preventDefault();
    setChildValues({
      ...cv,
    });
    setNewSplit(false);
    setModalOpen(true);
  };

  const handleCancel = () => {
    setModalOpen(false);
  };

  // will help translating timestamps to moment objects for the date pickers
  const getDateValue = (value) => {
    if (!value) {
      return moment();
    }

    if (isMoment(value)) {
      return value;
    }

    if (typeof value === 'string') {
      return moment(parseInt(value));
    }
  };

  const validLaPortDate = (china, la) => {
    if (!china) {
      return true;
    }
    if (typeof china === 'string') {
      china = moment(parseInt(china));
    }
    if (typeof la === 'string') {
      la = moment(parseInt(la));
    }
    let fourteenDaysFromChina = moment(china).add(14, 'days');
    let afterChina = moment(la).isAfter(fourteenDaysFromChina);
    if (!afterChina) {
      return false;
    }
    return true;
  };

  const isValidMoney = (str) => {
    return /^[0-9,.]*$/.test(str);
  };

  const isLessThanFiveDollars = (val) => {
    if (!val) return null;
    if (val.length === 0) return null;
    if (parseFloat(val) > 5) {
      return 'Cannot exceed more than $5 since it is per piece price';
    }
    if (!val.includes('.')) return null;
    let index = val.indexOf('.');
    if (val.length - index > 5) {
      return 'This value can NOT have more than 4 decimal places';
    }
    return null;
  };

  const isMoreThan4Dec = (value) => {
    if (!value) return null;
    if (value.length === 0) return null;
    if (!value.includes('.')) return null;
    let index = value.indexOf('.');
    if (value.length - index > 5) {
      return 'This value can NOT have more than 4 decimal places';
    }
    return null;
  };

  const handleCustomerInput = (values) => {
    // If no customer type selected, no errors.
    if (!values.customerType || !values.customerId || !values.endCustomerId) {
      return null;
    }
    if (
      values.customerId === values.endCustomerId &&
      values.customerType === 'distributor'
    ) {
      // Customer and End Customer cannot be the same when distributor is selected.
      return 'Customer & End Customer must be different';
    }
    if (
      values.customerId !== values.endCustomerId &&
      values.customerType !== 'distributor'
    ) {
      // Customer and End Customer cannot be the same when distributor is selected.
      return 'Customer & End Customer must be the same';
    }
  };

  const prePopulateEndCustomer = (values, newValue) => {
    if (values.customerId && newValue && newValue !== 'distributor') {
      setValues({
        ...values,
        endCustomerId: values.customerId,
        customerType: newValue,
      });
    } else {
      setValues({
        ...values,
        endCustomerId: null,
        customerType: newValue,
      });
    }
  };

  const isMoreThanOtherField = (values, amt) => {
    if (!amt) return null;
    if (amt.length === 0) return null;
    if (parseFloat(amt) > parseFloat(values.pricePerPiece)) {
      return 'Cost per Piece cannot be higher than Price per Piece!';
    }
    if (!amt.includes('.')) return null;
    let index = amt.indexOf('.');
    if (amt.length - index > 5) {
      return 'This value can NOT have more than 4 decimal places';
    }
    return null;
  };

  return (
    <>
      {errors && errors.length > 0 && (
        <ErrorArea>
          {errors.map((error) => (
            <ErrorText key={error}>
              <CloseCircleFilled style={{ marginRight: 8 }} />
              {error}
            </ErrorText>
          ))}
          <CloseOutlined
            onClick={onClearError}
            style={{
              position: 'absolute',
              right: 16,
              top: 16,
              fontSize: 20,
              cursor: 'pointer',
            }}
          />
        </ErrorArea>
      )}
      {!isNewEntry && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            position: 'relative',
            top: -32,
            height: 8,
          }}
        >
          <div style={{ marginRight: 24 }}>
            <FormLabel>Unique ID:</FormLabel>
            <Value>{values.id}</Value>
          </div>
          <div>
            <FormLabel>Entry Date:</FormLabel>
            <Value>
              {moment(parseInt(values.createdAt)).format(
                'MM / DD / YYYY | hh:mma'
              )}
            </Value>
          </div>
        </div>
      )}
      <form>
        <TabsWrapper>
          <Tabs
            defaultActiveKey='1'
            type='card'
            style={{ marginBottom: 32 }}
            large='large'
          >
            <TabPane tab='Step 1' key='1'>
              <Row gutter={24}>
                <Col xs={12}>
                  <FormItem
                    label='Customer'
                    required
                    error={handleCustomerInput(values)}
                  >
                    <CustomerInput
                      value={values.customerId}
                      defaultSearch={
                        values && values.customer && values.customer.title
                      } // use to make sure when were editing that we have the default selected item in the select's options
                      onChange={(newValue) =>
                        onChange({ customerId: newValue })
                      }
                    />
                  </FormItem>
                  <FormItem
                    label='Customer Type'
                    required
                    error={handleCustomerInput(values)}
                  >
                    <CustomerTypeInput
                      value={values.customerType}
                      onChange={(newValue) => {
                        onChange({ customerType: newValue });
                        prePopulateEndCustomer(values, newValue);
                      }}
                    />
                  </FormItem>
                  <FormItem
                    label='End Customer'
                    required
                    error={handleCustomerInput(values)}
                  >
                    {/* <Input
                      value={values.endCustomer}
                      onChange={(e) => onChange({endCustomer: e.target.value})}
                    /> */}
                    <CustomerInput
                      value={values.endCustomerId}
                      defaultSearch={
                        values && values.endCustomer && values.endCustomer.title
                      } // use to make sure when were editing that we have the default selected item in the select's options
                      onChange={(newValue) =>
                        onChange({ endCustomerId: newValue })
                      }
                    />
                  </FormItem>
                  <FormItem label='Item Number' required>
                    <Input
                      placeholder='*Item Number'
                      value={values.itemId || ''}
                      onChange={(e) => onChange({ itemId: e.target.value })}
                    />
                  </FormItem>
                  <FormItem label='Item Description' required>
                    <TextAreaInput
                      placeholder='Type description here...'
                      value={values.description || ''}
                      onChange={(e) =>
                        onChange({ description: e.target.value })
                      }
                    />
                  </FormItem>
                  <Break />
                  <FormItem label='Create New PO'>
                    <Input
                      placeholder='Type here to create a new PO...'
                      value={values.newPO || ''}
                      onChange={(e) =>
                        onChange({ newPO: e.target.value, poId: null })
                      }
                    />
                  </FormItem>
                  <FormItem label='Or, search existing POs' required>
                    <PoInput
                      value={values.poId}
                      defaultSearch={
                        values &&
                        values.purchaseOrder &&
                        values.purchaseOrder.title // use to make sure when were editing that we have the default selected item in the select's options
                      }
                      onChange={(newValue) =>
                        onChange({ poId: newValue, newPO: '' })
                      }
                    />
                  </FormItem>
                  <Break />
                  <FormItem label='Create New Batch #'>
                    <Input
                      placeholder='Type here to create a new Batch Number...'
                      value={values.newBatch || ''}
                      onChange={(e) =>
                        onChange({ newBatch: e.target.value, batchId: null })
                      }
                    />
                  </FormItem>
                  <FormItem label='Or, search existing batches' required>
                    <BatchesInput
                      placeholder='Select an existing batch #'
                      defaultSearch={
                        values && values.batch && values.batch.title
                      } // use to make sure when were editing that we have the default selected item in the select's options
                      value={values.batchId}
                      onChange={(newValue) =>
                        onChange({ batchId: newValue, newBatch: '' })
                      }
                    />
                  </FormItem>
                  {/* <Break /> */}
                </Col>
                <Col xs={12}>
                  <FormItem label='Vendor' required>
                    <VendorInput
                      value={values.vendorId}
                      defaultSearch={
                        values && values.vendor && values.vendor.title
                      } // use to make sure when were editing that we have the default selected item in the select's options
                      onChange={(newValue) => onChange({ vendorId: newValue })}
                    />
                  </FormItem>
                  <FormItem label='Product category' required>
                    <OrderCategory
                      value={values.orderCategory}
                      onChange={(newValue) =>
                        onChange({ orderCategory: newValue })
                      }
                    />
                  </FormItem>
                  <FormItem label='Order type' required>
                    <OrderTypeInput
                      value={values.orderType}
                      onChange={(newValue) => onChange({ orderType: newValue })}
                    />
                  </FormItem>
                  <FormItem>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <FormLabel style={{ width: 100 }}>
                        Order date <Red>*</Red>
                      </FormLabel>
                      <DateInput
                        value={getDateValue(values.orderDate)}
                        onChange={(newValue) =>
                          onChange({ orderDate: newValue })
                        }
                        disabledDate={(current) => {
                          // Can not choose a date greater than today
                          return current && current > moment().endOf('day');
                        }}
                      />
                    </div>
                  </FormItem>
                  <FormItem>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <FormLabel style={{ width: 100 }}>
                        Due Date <Red>*</Red>
                      </FormLabel>
                      <DateInput
                        value={getDateValue(values.dueDate)}
                        onChange={(newValue) => onChange({ dueDate: newValue })}
                      />
                    </div>
                  </FormItem>
                  <FormItem label='Order quantity' required>
                    <Input
                      type='number'
                      value={values.orderQuantity || ''}
                      onChange={(e) =>
                        onChange({ orderQuantity: parseInt(e.target.value) })
                      }
                    />
                  </FormItem>
                  <FormItem
                    label='Price per piece'
                    required
                    error={isMoreThan4Dec(values.pricePerPiece)}
                  >
                    <Input
                      value={values.pricePerPiece || ''}
                      onChange={(e) => {
                        if (isValidMoney(e.target.value)) {
                          onChange({
                            pricePerPiece: e.target.value.replace(/,/g, ''),
                          });
                        }
                      }}
                    />
                  </FormItem>
                  <FormItem>
                    <Calculated
                      label='Order amount'
                      value={values.orderAmount || ''}
                    />
                  </FormItem>
                  {/* <FormItem
                  label="Order amount"
                  required
                  error={
                    values.orderAmount && !isDollars(values.orderAmount)
                      ? 'Order amount must be formatted as 0.00'
                      : null
                  }
                >
                  <Input
                    value={values.orderAmount || ''}
                    disabled
                    onChange={(e) => {
                      if (isValidMoney(e.target.value)) {
                        onChange({orderAmount: e.target.value});
                      }
                    }}
                  />
                </FormItem> */}
                  <ButtonArea
                    isNewEntry={isNewEntry}
                    loading={loading}
                    onSubmit={onSubmit}
                    handleDelete={handleDelete}
                    values={values}
                  />
                </Col>
              </Row>
            </TabPane>

            <TabPane tab='Step 2' key='2'>
              <Row gutter={24}>
                <Col xs={12}>
                  <FormItem label='Order Status' required>
                    <OrderStatusInput
                      value={values.orderStatus}
                      onChange={(newValue) => {
                        // if user resets the orderStatus to underExecution, clear out the values associated with a executed order
                        if (newValue === 'underExecution') {
                          return onChange({
                            orderStatus: newValue,
                            invoiceAmount: 0,
                            airFreightCost: '0',
                            airFreightCostPerPiece: '0',
                            localTruckingCost: '0',
                            tariffs: '0',
                            totalExecuted: null,
                            executedDate: null,
                          });
                        }
                        if (newValue === 'partiallyExecuted') {
                          return onChange({
                            orderStatus: newValue,
                            totalExecuted: null,
                            balanceQty:
                              values.balanceQty !== null
                                ? values.balanceQty
                                : values.orderQuantity,
                          });
                        }
                        if (newValue === 'executed') {
                          return onChange({
                            orderStatus: newValue,
                            executedDate: moment(),
                            airFreightCost: values.airFreightCost || '0.00',
                            localTruckingCost:
                              values.localTruckingCost || '0.00',
                            otherAdditionalCharges:
                              values.otherAdditionalCharges || '0.00',
                            tariffs: values.tariffs || '0.00',
                            invoiceAmount: values.invoiceAmount || 0,
                          });
                        }
                        if (newValue === 'executedWithSplits') {
                          return onChange({
                            orderStatus: newValue,
                            executedDate: moment(),
                            airFreightCost: values.airFreightCost || '0.00',
                            localTruckingCost:
                              values.localTruckingCost || '0.00',
                            otherAdditionalCharges:
                              values.otherAdditionalCharges || '0.00',
                            tariffs: values.tariffs || '0.00',
                            invoiceAmount: values.invoiceAmount || 0,
                          });
                        }
                      }}
                    />
                  </FormItem>
                  {values.orderStatus === 'executed' && (
                    <FormItem>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <FormLabel style={{ width: 100 }}>
                          Executed Date
                        </FormLabel>
                        <DateInput
                          value={
                            values.executedDate
                              ? getDateValue(values.executedDate)
                              : undefined
                          }
                          onChange={(newValue) =>
                            onChange({ executedDate: newValue })
                          }
                        />
                      </div>
                    </FormItem>
                  )}

                  <FormItem
                    label='PO Status'
                    hint='* Status change applies to THIS entry only'
                  >
                    <PoStatusInput
                      value={values.poStatus}
                      onChange={(poStatus) => {
                        if (poStatus === 'CANCELLED') {
                          return onChange({
                            poStatus,
                            orderAmount: 0,
                          });
                        }
                        return onChange({
                          poStatus,
                        });
                      }}
                    />
                  </FormItem>
                </Col>
                <Col xs={12}>
                  {values.orderStatus !== 'executed' ||
                    (values.orderStatus !== 'partiallyExecuted' && (
                      <>
                        <FormItem label='Ship to Quantity' required>
                          <Input
                            type='number'
                            value={values.shipToQuantity}
                            onChange={(e) =>
                              onChange({
                                shipToQuantity: parseInt(e.target.value),
                              })
                            }
                          />
                        </FormItem>
                        <FormItem
                          label='Air Freight Price'
                          error={
                            values.airFreightCost &&
                            !isDollars(values.airFreightCost)
                              ? 'Air Freight Price must be formatted as 0.00'
                              : null
                          }
                        >
                          <Input
                            value={values.airFreightCost || ''}
                            onChange={(e) => {
                              if (isValidMoney(e.target.value)) {
                                onChange({ airFreightCost: e.target.value });
                              }
                            }}
                          />
                        </FormItem>
                        <FormItem
                          label='Air Freight Cost'
                          error={isLessThanFiveDollars(
                            values.airFreightCostPerPiece
                          )}
                        >
                          <Input
                            value={values.airFreightCostPerPiece || ''}
                            onChange={(e) => {
                              if (isValidMoney(e.target.value)) {
                                onChange({
                                  airFreightCostPerPiece: e.target.value,
                                });
                              }
                            }}
                          />
                        </FormItem>
                        <FormItem
                          label='Local Trucking Cost'
                          error={
                            values.localTruckingCost &&
                            !isDollars(values.localTruckingCost)
                              ? 'Local Trucking Cost must be formatted as 0.00'
                              : null
                          }
                        >
                          <Input
                            value={values.localTruckingCost || ''}
                            onChange={(e) => {
                              if (isValidMoney(e.target.value)) {
                                onChange({ localTruckingCost: e.target.value });
                              }
                            }}
                          />
                        </FormItem>
                        <FormItem
                          label='Tariffs'
                          error={
                            values.tariffs && !isDollars(values.tariffs)
                              ? 'Tariffs must be formatted as 0.00'
                              : null
                          }
                        >
                          <Input
                            value={values.tariffs || ''}
                            onChange={(e) => {
                              if (isValidMoney(e.target.value)) {
                                onChange({ tariffs: e.target.value });
                              }
                            }}
                          />
                        </FormItem>
                        <FormItem
                          label='Other Additional Charges'
                          error={
                            values.otherAdditionalCharges &&
                            !isDollars(values.otherAdditionalCharges)
                              ? 'Other additional charges must be formatted as 0.00'
                              : null
                          }
                        >
                          <Input
                            value={values.otherAdditionalCharges || ''}
                            onChange={(e) => {
                              if (isValidMoney(e.target.value)) {
                                onChange({
                                  otherAdditionalCharges: e.target.value,
                                });
                              }
                            }}
                          />
                        </FormItem>
                        <FormItem
                          label='Discount'
                          error={
                            values.discount && !isDollars(values.discount)
                              ? 'Discount must be formatted as 0.00'
                              : null
                          }
                        >
                          <Input
                            value={values.discount || ''}
                            onChange={(e) => {
                              if (isValidMoney(e.target.value)) {
                                onChange({ discount: e.target.value });
                              }
                            }}
                          />
                        </FormItem>
                        <FormItem>
                          <Calculated
                            label='Invoice amount'
                            value={values.invoiceAmount || ''}
                          />
                        </FormItem>
                        {/* <FormItem
                      label="Invoice amount"
                      required={values.orderStatus === 'executed'}
                      error={isMoreThan4Dec(values.invoiceAmount)}
                    >
                      <Input
                        placeholder="*Invoice amount"
                        value={values.invoiceAmount || ''}
                        disabled
                        onChange={(e) => {
                          if (isValidMoney(e.target.value)) {
                            onChange({invoiceAmount: e.target.value});
                          }
                        }}
                      />
                    </FormItem> */}
                      </>
                    ))}
                  {values.orderStatus === 'partiallyExecuted' && (
                    <FormItem>
                      <NoChangeField
                        label='Balance Quantity'
                        value={values.balanceQty}
                      />
                    </FormItem>
                  )}
                  {values.orderStatus === 'executedWithSplits' && (
                    <FormItem>
                      <NoChangeField
                        label='Total Ship To Quantity (all splits)'
                        value={values.shipToQuantity}
                      />
                    </FormItem>
                  )}
                  {values.orderStatus !== 'underExecution' && (
                    <FormItem>
                      <Calculated
                        label={
                          values.orderStatus === 'executedWithSplits'
                            ? 'Total Executed (all splits)'
                            : 'Total Executed'
                        }
                        value={values.totalExecuted}
                      />
                    </FormItem>
                  )}
                  {(values.childSplits &&
                    values.childSplits.length > 0 &&
                    values.orderStatus === 'partiallyExecuted') ||
                  values.orderStatus === 'executedWithSplits'
                    ? values.childSplits.map((x, i) => {
                        return (
                          <ChildSplitBtn
                            key={x.id}
                            onClick={(e) => showEditChildModal(e, x)}
                          >{`Split ${i + 1} - modified ${moment(
                            parseInt(x.updatedAt)
                          ).format('MM / DD / YYYY')}`}</ChildSplitBtn>
                        );
                      })
                    : undefined}
                  <SplitModal
                    handleCancel={handleCancel}
                    modalOpen={modalOpen}
                    values={values}
                    childValues={childValues}
                    setChildValues={setChildValues}
                    onChangeChildSplits={onChangeChildSplits}
                    isDollars={isDollars}
                    getDateValue={getDateValue}
                    isValidMoney={isValidMoney}
                    Calculated={Calculated}
                    isSplit={isSplit}
                    setIsSplit={setIsSplit}
                    newSplit={newSplit}
                    onCancel={onCancel}
                  />
                  <ButtonArea
                    values={values}
                    isNewEntry={isNewEntry}
                    orderStatus={values.orderStatus}
                    loading={loading}
                    showModal={showModal}
                    onSubmit={onSubmit}
                    handleDelete={handleDelete}
                    showExecutedModal={showExecutedModal}
                  />
                </Col>
              </Row>
            </TabPane>
            <TabPane tab='Step 3' key='3'>
              <Row gutter={24}>
                <Col xs={12}>
                  <FormItem label='Ship to address'>
                    <Input
                      placeholder='Ship to address'
                      value={values.shipToAddress}
                      onChange={(e) =>
                        onChange({ shipToAddress: e.target.value })
                      }
                    />
                  </FormItem>
                  <div style={{ display: 'flex' }}>
                    <FormItem label='City'>
                      <Input
                        value={values.city}
                        onChange={(e) => onChange({ city: e.target.value })}
                      />
                    </FormItem>
                    <FormItem
                      label='State'
                      containerProps={{ style: { marginLeft: 4 } }}
                    >
                      <Input
                        value={values.state}
                        onChange={(e) => onChange({ state: e.target.value })}
                      />
                    </FormItem>
                    <FormItem
                      label='Zip'
                      containerProps={{ style: { marginLeft: 4 } }}
                    >
                      <Input
                        value={values.zip}
                        onChange={(e) => onChange({ zip: e.target.value })}
                      />
                    </FormItem>{' '}
                  </div>
                  <FormItem label='GRT'>
                    <Input
                      value={values.GRT}
                      onChange={(e) => onChange({ GRT: e.target.value })}
                    />
                  </FormItem>
                  <FormItem label='Payment Type'>
                    <PaymentTypeInput
                      value={values.paymentType}
                      onChange={(paymentType) => onChange({ paymentType })}
                    />
                  </FormItem>
                  <FormItem
                    label='Tariffs Paid'
                    error={
                      values.tariffsPaid && !isDollars(values.tariffsPaid)
                        ? 'Tariffs Paid must be formatted as 0.00'
                        : null
                    }
                  >
                    <Input
                      value={values.tariffsPaid || ''}
                      onChange={(e) => {
                        if (isValidMoney(e.target.value)) {
                          onChange({ tariffsPaid: e.target.value });
                        }
                      }}
                    />
                  </FormItem>
                  <FormItem
                    label='Customs Brokerage Fees'
                    error={
                      values.customsFees && !isDollars(values.customsFees)
                        ? 'Customs Brokerage Fees must be formatted as 0.00'
                        : null
                    }
                  >
                    <Input
                      value={values.customsFees || ''}
                      onChange={(e) => {
                        if (isValidMoney(e.target.value)) {
                          onChange({ customsFees: e.target.value });
                        }
                      }}
                    />
                  </FormItem>
                  <FormItem
                    label='Ditto Trucking Cost'
                    error={
                      values.dittoTruckingCost &&
                      !isDollars(values.dittoTruckingCost)
                        ? 'Ditto Trucking Cost must be formatted as 0.00'
                        : null
                    }
                  >
                    <Input
                      value={values.dittoTruckingCost || ''}
                      onChange={(e) => {
                        if (isValidMoney(e.target.value)) {
                          onChange({ dittoTruckingCost: e.target.value });
                        }
                      }}
                    />
                  </FormItem>
                </Col>
                <Col xs={12}>
                  <FormItem label='Payment Date'>
                    <DateInput
                      value={getDateValue(values.paymentDate)}
                      onChange={(newValue) =>
                        onChange({ paymentDate: newValue })
                      }
                    />
                  </FormItem>
                  <FormItem label='ETD China'>
                    <DateInput
                      value={getDateValue(values.etdChina)}
                      onChange={(newValue) => onChange({ etdChina: newValue })}
                    />
                  </FormItem>
                  <FormItem
                    label='ETD LA Port'
                    error={
                      values.etdLaPort &&
                      !validLaPortDate(values.etdChina, values.etdLaPort)
                        ? 'ETD LA Port must be at least 14 days after ETD China'
                        : null
                    }
                  >
                    <DateInput
                      value={getDateValue(values.etdLaPort)}
                      onChange={(newValue) => onChange({ etdLaPort: newValue })}
                    />
                  </FormItem>
                  <FormItem label='Remarks'>
                    <TextAreaInput
                      placeholder='Remarks'
                      value={values.remarks || ''}
                      onChange={(e) => onChange({ remarks: e.target.value })}
                    />
                  </FormItem>
                  <FormItem
                    label='Cost per Piece'
                    error={isMoreThanOtherField(values, values.cost)}
                  >
                    <Input
                      value={values.cost || ''}
                      onChange={(e) => {
                        if (isValidMoney(e.target.value)) {
                          onChange({ cost: e.target.value });
                        }
                      }}
                    />
                  </FormItem>
                  <FormItem
                    label='Mold Cost'
                    error={isMoreThan4Dec(values.moldCost)}
                  >
                    <Input
                      value={values.moldCost || ''}
                      onChange={(e) => {
                        if (isValidMoney(e.target.value)) {
                          onChange({ moldCost: e.target.value });
                        }
                      }}
                    />
                  </FormItem>
                  <FormItem
                    label='Other Cost'
                    error={isMoreThan4Dec(values.otherCost)}
                  >
                    <Input
                      value={values.otherCost || ''}
                      onChange={(e) => {
                        if (isValidMoney(e.target.value)) {
                          onChange({ otherCost: e.target.value });
                        }
                      }}
                    />
                  </FormItem>
                  <FormItem
                    label='Sample Cost'
                    error={isMoreThan4Dec(values.sampleCost)}
                  >
                    <Input
                      value={values.sampleCost || ''}
                      onChange={(e) => {
                        if (isValidMoney(e.target.value)) {
                          onChange({ sampleCost: e.target.value });
                        }
                      }}
                    />
                  </FormItem>
                  <ButtonArea
                    isNewEntry={isNewEntry}
                    loading={loading}
                    onSubmit={onSubmit}
                    handleDelete={handleDelete}
                  />
                </Col>
              </Row>
            </TabPane>
          </Tabs>
        </TabsWrapper>
      </form>
      <ExecutedSplitModal
        executedModal={executedModal}
        setExecutedModal={setExecutedModal}
        onSubmit={onSubmit}
        values={values}
        setValues={setValues}
      />
    </>
  );
}
