import {gql} from '@apollo/client';

export default gql`
  query purchaseOrders($searchText: String) {
    purchaseOrders(searchText: $searchText) {
      id
      title
    }
  }
`;
