// TOP LEVEL IMPORTS
import React from 'react';
import {Route, Redirect} from 'react-router-dom';

// EXPORTED COMPONENT
// ==============================
const PublicRoute = (props) => {
  const {
    currentUser,
    redirectOnAuth,
    showHeader,
    showFooter,
    component: Component,
    path,
    backgroundColor,
    layout: Layout,
    ...rest
  } = props;

  if (
    currentUser &&
    currentUser.id &&
    currentUser.roles &&
    currentUser.roles.includes('superAdmin')
  ) {
    return <Redirect to="/admin" />;
  }

  return (
    <Route
      {...rest}
      path={path}
      render={(args) => {
        return (
          <Layout
            currentUser={currentUser}
            backgroundColor={backgroundColor}
            showHeader={showHeader}
            showFooter={showFooter}
          >
            <Component currentUser={currentUser} {...args} />
          </Layout>
        );
      }}
    />
  );
};

// PROPS
// ==============================
PublicRoute.defaultProps = {
  redirectOnAuth: true,
};

// EXPORT
// ==============================
export default PublicRoute;
