import React, {useState} from 'react';
import styled from 'styled-components';
import moment, {isMoment} from 'moment-timezone';
// COMPONENTS
import RangeComponent from 'components/common/RangeComponent';
import SideNav from './SideNav';
import OrdersReport from './OrdersReport';
import SalesReport from './SalesReport';
import SalesByMonthReport from './SalesByMonthReport';
import SalesByTop5Report from './SalesByTop5Report';
import SalesByCategoryReport from './SalesByCategoryReport';

const Row = styled.div`
  display: flex;
`;

const TopRow = styled.div`
  height: 160px;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;
`;

// const LIMIT = 10;

const DataBlock = styled.div`
  width: 100%;
`;

const getStartDate = (startDate) => {
  if (startDate && isMoment(startDate)) {
    return startDate.startOf('day').valueOf().toString();
  }
};

const getEndDate = (endDate) => {
  if (endDate && isMoment(endDate)) {
    return endDate.endOf('day').valueOf().toString();
  }
};

export default function AdminReports() {
  const [tab, setTab] = useState('sales');
  const [startDate, setStartDate] = useState(
    moment().subtract(30, 'days').startOf('day')
  );
  const [endDate, setEndDate] = useState(moment().endOf('day'));

  const TABS = [
    // {
    //   label: 'Orders',
    //   activeValue: 'orders',
    //   onClick: () => setTab('orders'),
    // },
    {
      label: 'Sales',
      activeValue: 'sales',
      onClick: () => setTab('sales'),
    },
    {
      label: 'Sales by Month',
      activeValue: 'salesByMonth',
      onClick: () => setTab('salesByMonth'),
    },
    {
      label: 'Sales by Category',
      activeValue: 'salesByCategory',
      onClick: () => setTab('salesByCategory'),
    },
    {
      label: 'Sales by Top 5',
      activeValue: 'salesByTop5',
      onClick: () => setTab('salesByTop5'),
    },
  ];

  let sharedProps = {
    startDate: getStartDate(startDate),
    endDate: getEndDate(endDate),
  };

  return (
    <>
      <TopRow>
        <div>
          <RangeComponent
            value={[startDate, endDate]}
            onChange={({startDate, endDate}) => {
              setStartDate(startDate);
              setEndDate(endDate);
            }}
          />
        </div>
      </TopRow>
      <Row>
        <SideNav items={TABS} tab={tab} />
        <DataBlock>
          {tab === 'orders' && <OrdersReport {...sharedProps} />}
          {tab === 'sales' && <SalesReport {...sharedProps} />}
          {tab === 'salesByMonth' && <SalesByMonthReport {...sharedProps} />}
          {tab === 'salesByCategory' && (
            <SalesByCategoryReport {...sharedProps} />
          )}
          {tab === 'salesByTop5' && <SalesByTop5Report {...sharedProps} />}
        </DataBlock>
      </Row>
    </>
  );
}
