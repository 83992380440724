import React, { useState } from 'react';
import moment, { isMoment } from 'moment-timezone';
import styled from 'styled-components';
// COMPONENTS
import Button from 'components/common/Button';
import RangeComponent from 'components/common/RangeComponent';
// HOOKs
import useDownloadCSV from './useDownloadCSV.js';

const TopRow = styled.div`
  height: 160px;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;
`;

const getStartDate = (startDate) => {
  if (startDate && isMoment(startDate)) {
    return startDate.startOf('day').valueOf().toString();
  }
};

const getEndDate = (endDate) => {
  if (endDate && isMoment(endDate)) {
    return endDate.endOf('day').valueOf().toString();
  }
};

export default function AdminDashboard() {
  const [startDate, setStartDate] = useState(
    moment().subtract(30, 'days').startOf('day')
  );
  const [endDate, setEndDate] = useState(moment().endOf('day'));
  const variables = {
    startDate: getStartDate(startDate),
    endDate: getEndDate(endDate),
  };

  const { downloading, onDownload } = useDownloadCSV(variables);

  return (
    <>
      <TopRow>
        <div>
          <RangeComponent
            value={[startDate, endDate]}
            onChange={({ startDate, endDate }) => {
              setStartDate(startDate);
              setEndDate(endDate);
            }}
          />
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              onClick={() => onDownload({ full: false })}
              disabled={downloading}
              secondary
              style={{ width: 195, marginRight: 16 }}
            >
              {!downloading ? ' Export Goodring CSV' : '...'}
            </Button>
            <Button
              onClick={() =>
                onDownload({ fullHistory: true, executedData: true })
              }
              disabled={downloading}
              style={{ width: 195, marginRight: 16 }}
            >
              {!downloading ? ' Export Executed Data' : '...'}
            </Button>
            <Button
              onClick={() => onDownload({ full: true, fullHistory: true })}
              disabled={downloading}
              style={{ width: 165 }}
            >
              {!downloading ? ' Export All Data' : '...'}
            </Button>
          </div>
        </div>
      </TopRow>
      {/* <Table
        loading={loading}
        rowKey="id"
        columns={COLUMNS}
        dataSource={
          (!loading && data && data.entries && data.entries.entries) || []
        }
        scroll={{x: 1300}}
        pagination={{
          pageSize: LIMIT,
          total: (data && data.entries && data.entries.count) || 0,
          current: current || 1,
          onChange: (page) => {
            setSkip(page === 1 ? 0 : (page - 1) * LIMIT);
            setCurrent(page);
          },
        }}
      /> */}
    </>
  );
}
