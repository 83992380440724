import React, {useState} from 'react';
import Select from 'components/inputs/SelectInput';
import DropdownStyleWrapper from 'components/inputs/DropdownStyleWrapper';
import {useQuery} from '@apollo/client';
import VENDORS from 'ApolloClient/Queries/vendors';
import {useEffect} from 'react';

const {Option} = Select;

export default function VendorInput({value, onChange, defaultSearch}) {
  const [searchText, setSearchText] = useState('');
  const {data, loading} = useQuery(VENDORS, {
    variables: {
      searchText,
    },
  });

  useEffect(() => {
    setSearchText(defaultSearch);
  }, [defaultSearch]);

  return (
    <DropdownStyleWrapper>
      <Select
        value={value}
        onSearch={(value) => setSearchText(value)}
        showSearch
        placeholder="Vendor"
        style={{width: '100%'}}
        showArrow={false}
        filterOption={false}
        notFoundContent={loading ? 'searching...' : null}
        onChange={onChange}
      >
        {data &&
          data.vendors &&
          data.vendors.map((item) => {
            return <Option key={item.id}>{item.title}</Option>;
          })}
      </Select>
    </DropdownStyleWrapper>
  );
}
