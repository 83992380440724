import React from 'react';
// APOLLO
import REPORT_SALES from 'ApolloClient/Queries/reportSales';
import { useQuery } from '@apollo/client';
// COMPONENTS
import Table from 'components/common/Table';
// LIB
import helpers from 'lib/helpers';

const COLUMNS = [
  {
    title: 'UNDER EXECUTION',
    key: '__typename',
    render: (record) => helpers.centsToDollars(record.underExecution),
  },
  // {
  //   title: 'EXECUTED',
  //   render: (record) => helpers.centsToDollars(record.executed),
  // },
  {
    title: 'NEW SALES',
    render: (record) => helpers.centsToDollars(record.newSales),
  },
  // {
  //   title: 'TOTAL',
  //   render: (record) => helpers.centsToDollars(record.total),
  // },
];

export default function SalesReport({ startDate, endDate }) {
  const { data, loading, error } = useQuery(REPORT_SALES, {
    variables: {
      startDate,
      endDate,
    },
    fetchPolicy: 'network-only',
    skip: !endDate || !startDate,
  });

  if (loading) return 'loading...';
  if (error) return 'error...';

  console.log(data.reportSales);
  return (
    <Table
      columns={COLUMNS}
      dataSource={[data.reportSales]}
      rowKey='underExecution'
    />
  );
}
