import React, {useState} from 'react';
import queryString from 'query-string';
import styled from 'styled-components';
import moment from 'moment';
// COMPONENTS
import Table from 'components/common/Table';
import LinkButton from 'components/common/LinkButton';
import EditEntry from './EditEntry';
import TextInput from 'components/inputs/TextInput';
import SearchByInput from './SearchByInput';
// APOLLO
import ENTRIES from 'ApolloClient/Queries/entries';
import {useQuery} from '@apollo/client';

const TopRow = styled.div`
  display: flex;
  height: 80px;
`;

// const LIMIT = 5;

export default function AdminSearch({location, history}) {
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(5);
  const [current, setCurrent] = useState(1);
  const [searchBy, setSearchBy] = useState('itemId');
  const [searchText, setSearchText] = useState(undefined);
  const {selectedId} = queryString.parse(location.search);

  const setSelectedId = (id) => {
    if (!id) return history.push(`/admin/search`);
    history.push(`/admin/search?selectedId=${id}`);
  };

  const variables = {
    searchText,
    searchBy,
    skip,
    limit,
  };

  const {data, loading} = useQuery(ENTRIES, {
    variables,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const COLUMNS = [
    {
      title: 'ENTRY DATE',
      key: 'id',
      width: 200,
      render: (record) =>
        record.createdAt &&
        moment(parseInt(record.createdAt)).format('MM/DD/YYYY  hh:mma'),
    },
    {
      title: 'ORDER DATE',
      render: (record) =>
        record.orderDate &&
        moment(parseInt(record.orderDate)).format('MM/DD/YYYY'),
    },
    {
      title: 'SYSTEM ID',
      render: (record) => (
        <LinkButton onClick={() => setSelectedId(record.id)}>
          {record.id}
        </LinkButton>
      ),
    },
    {
      title: 'BATCH',
      render: (record) => record.batch && record.batch.title,
    },
    {
      title: 'PO',
      render: (record) => record.purchaseOrder && record.purchaseOrder.title,
    },
    {
      title: 'ITEM #',
      render: (record) => record.itemId,
    },
  ];

  if (selectedId) {
    return (
      <EditEntry
        id={selectedId}
        onCancel={() => setSelectedId(null)}
        refetchQueries={[
          {
            query: ENTRIES,
            variables,
          },
        ]}
      />
    );
  }

  return (
    <>
      <TopRow>
        <div>
          <div style={{position: 'relative', bottom: -16}}>
            <TextInput
              dark
              width={'600px'}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
        </div>
        <SearchByInput
          value={searchBy}
          onChange={(searchBy) => setSearchBy(searchBy)}
        />
      </TopRow>
      <Table
        columns={COLUMNS}
        loading={loading}
        dataSource={(data && data.entries && data.entries.entries) || []}
        pagination={{
          pageSize: limit,
          onShowSizeChange: (current, pageSize) => {
            setLimit(pageSize);
            setSkip(0);
          },
          total: (data && data.entries && data.entries.count) || 0,
          current: current || 1,
          onChange: (page) => {
            setSkip(page === 1 ? 0 : (page - 1) * limit);
            setCurrent(page);
          },
        }}
      />
    </>
  );
}
