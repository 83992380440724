const theme = {
  fontFamily: `'Lato', sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
  // COLORS
  colors: {
    primary: '#22a7c5',
    darkBlue: '#0a1637',
    // FORMS
    fieldBackground: '#ebf3f5',
    fieldText: '#508e9c',
    activeFieldBackground: '#dce6e8',
    activeFieldText: '#0f3557',
    placeholder: '#528e9b',
    // other
    primary1: '#005364', // darker
    primary2: '#04323f', // darkest
    primary3: '#05B4BE', // light
    primary4: '#ebf4f5', // lightest

    // SUPPORT
    support1: '#00C7D3',
    // Neutrals
    neutral1: '#1F2933',
    neutral2: '#323F4B',
    neutral3: '#3E4C59',
    neutral4: '#52606D',
    neutral5: '#616E7C',
    neutral6: '#7B8794',
    neutral7: '#9AA5B1',
    neutral8: '#CBD2D9',
    neutral9: '#E4E7EB',
    neutral10: '#F5F7FA',
    // Red
    red1: '#610404',
    red2: '#780A0A',
    red3: '#911111',
    red4: '#A61B1B',
    red5: '#BA2525',
    red6: '#D64545',
    red7: '#E66A6A',
    red8: '#F29B9B',
    red9: '#FACDCD',
    red10: '#FFEEEE',
    // Yellow
    yellow1: '#513C06',
    yellow2: '#7C5E10',
    yellow3: '#A27C1A',
    yellow4: '#C99A2E',
    yellow5: '#E9B949',
    yellow6: '#F7D070',
    yellow7: '#F9DA8B',
    yellow8: '#F8E3A3',
    yellow9: '#FCEFC7',
    yellow10: '#FFFAEB',
  },
};

export default theme;
