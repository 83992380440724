import { gql } from '@apollo/client';

export default gql`
  query entriesCSV(
    $searchText: String
    $searchBy: String
    $startDate: String
    $fullHistory: Boolean
    $executedData: Boolean
    $endDate: String
    $skip: Int
    $limit: Int
  ) {
    entriesCSV(
      searchText: $searchText
      searchBy: $searchBy
      startDate: $startDate
      endDate: $endDate
      skip: $skip
      limit: $limit
      fullHistory: $fullHistory
      executedData: $executedData
    ) {
      id
      itemId
      description
      cost
      airFreightCostPerPiece
      moldCost
      sampleCost
      otherCost
      createdAt
      orderDate
      shipToAddress
      city
      zip
      state
      orderStatus
      orderCategory
      pricePerPiece
      orderAmount
      orderType
      orderDate
      shipToQuantity
      balanceQty
      otherAdditionalCharges
      dueDate
      updatedAt
      remarks
      orderQuantity
      invoiceAmount
      paymentType
      GRT
      paymentDate
      airFreightCost
      localTruckingCost
      tariffs
      dittoTruckingCost
      customsFees
      tariffsPaid
      etdLaPort
      etdChina
      otherAdditionalCharges
      totalExecuted
      poStatus
      childSplits {
        id
        batchTitle
        batchId
        shipToQuantity
        airFreightCost
        tariffs
        localTruckingCost
        otherAdditionalCharges
        totalExecuted
        discount
        invoiceAmount
        totalExecuted
        executedDate
        createdAt
        updatedAt
      }
      vendor {
        id
        title
      }
      customer {
        id
        title
      }
      batch {
        id
        title
      }
      purchaseOrder {
        id
        title
      }
      executedDate
      discount
      endCustomer {
        id
        title
      }
    }
  }
`;
