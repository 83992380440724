import { gql } from '@apollo/client';

export default gql`
  query entries(
    $searchText: String
    $searchBy: String
    $startDate: String
    $endDate: String
    $skip: Int
    $limit: Int
  ) {
    entries(
      searchText: $searchText
      searchBy: $searchBy
      startDate: $startDate
      endDate: $endDate
      skip: $skip
      limit: $limit
    ) {
      count
      entries {
        id
        itemId
        balanceQty
        description
        createdAt
        orderDate
        discount
        orderStatus
        orderCategory
        orderQuantity
        orderAmount
        remarks
        pricePerPiece
        customerType
        orderType
        childSplits {
          id
        }
        vendor {
          id
          title
        }
        customer {
          id
          title
        }
        batch {
          id
          title
        }
        purchaseOrder {
          id
          title
        }
      }
    }
  }
`;
