import React from 'react';
import Select from 'components/inputs/SelectInput';
import DropdownStyleWrapper from 'components/inputs/DropdownStyleWrapper';

const { Option } = Select;

export default function CustomerTypeInput({ value, onChange }) {
  return (
    <DropdownStyleWrapper>
      <Select
        value={value}
        placeholder='Customer Type'
        style={{ width: '100%' }}
        onChange={onChange}
      >
        <Option value='distributor'>DISTRIBUTOR</Option>
        <Option value='directCustomer'>DIRECT CUSTOMER</Option>
        <Option value='todCustomer'>TOD CUSTOMER</Option>
      </Select>
    </DropdownStyleWrapper>
  );
}
