// TOP LEVEL IMPORTS
import { gql } from '@apollo/client';

export default gql`
  mutation saveSplit($newSplit: Boolean, $id: ID, $params: ChildEntryInput) {
    saveSplit(newSplit: $newSplit, id: $id, params: $params) {
      id
      balanceQty
    }
  }
`;
