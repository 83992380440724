import React from 'react';
import styled from 'styled-components';
import AntdTable from 'antd/lib/table';
import 'antd/lib/table/style/css';

const Wrapper = styled.div`
  th {
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }
  /* ================================================
    TABLE STYLES OVERWRITING ANTD TABLE DEFAULT STYLES 
  ==================================================== */
  th {
    background: ${(p) => p.theme.colors.primary4} !important;
  }

  .ant-table-header-column {
    text-transform: uppercase;
    color: #fff !important;
    letter-spacing: 1.5px;
    font-size: 12px !important;
  }
  .ant-table-thead > tr > th {
    border-bottom: 0px !important;
  }

  .ant-pagination-item-active a {
    color: #5984a5 !important;
  }

  .ant-pagination-item:focus a,
  .ant-pagination-item:hover a {
    color: ${(p) => p.theme.colors.primary} !important;
  }

  .ant-pagination-prev:focus .ant-pagination-item-link,
  .ant-pagination-next:focus .ant-pagination-item-link,
  .ant-pagination-prev:hover .ant-pagination-item-link,
  .ant-pagination-next:hover .ant-pagination-item-link {
    color: ${(p) => p.theme.colors.primary} !important;
    border-color: ${(p) => p.theme.colors.primary} !important;
  }

  .ant-pagination-item:focus,
  .ant-pagination-item:hover {
    border-color: ${(p) => p.theme.colors.primary} !important;
  }

  .ant-pagination-item-active {
    border-color: #5984a5 !important;
  }

  .ant-table-cell {
    color: #5984a5 !important;
  }

  th {
    padding: 8px !important;
    font-weight: 600;
  }
  table th:first-child {
    border-top-left-radius: 30px !important;
    border-bottom-left-radius: 30px !important;
    padding-left: 16px !important;
  }
  table th:last-child {
    border-top-right-radius: 30px !important;
    border-bottom-right-radius: 30px !important;
  }
  tbody
    > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
    > td {
    background: transparent !important;
  }
`;

export default function Table(props) {
  return (
    <Wrapper>
      <AntdTable
        loading={props.loading}
        onRow={props.onRow}
        onChange={props.onChange}
        rowKey={(record) => record.id}
        dataSource={props.dataSource}
        columns={props.columns}
        pagination={{showSizeChanger: false, ...props.pagination}}
        {...props}
      />
    </Wrapper>
  );
}
