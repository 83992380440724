import {gql} from '@apollo/client';

export default gql`
  query reportSalesByMonth($startDate: String, $endDate: String) {
    reportSalesByMonth(startDate: $startDate, endDate: $endDate) {
      _id {
        month
        year
      }
      total
    }
  }
`;
