import React from 'react';
import styled from 'styled-components';
import {validate} from 'email-validator';
//COMPONENTS
import TextInput from 'components/inputs/TextInput';
import Button from 'components/common/Button';
import FormItem from 'components/common/FormItem';
import ErrorBlock from 'components/common/ErrorBlock';
import Background from 'components/common/GradientBackground';
// LIB
import AuthHelpers from 'lib/helpers/AuthHelpers';
import logo from 'lib/media/logo.png';
// APOLLO
import ApolloClient from 'ApolloClient/index.js';

const FormContainer = styled.form`
  width: 250px;
  margin: auto;
  max-width: 100%;
  padding-top: 96px;
`;

class AuthLogin extends React.PureComponent {
  state = {
    loading: false,
    errors: [],
  };
  onSubmit = async (e) => {
    if (e.preventDefault) {
      e.preventDefault();
    }

    // reset errors
    this.setState({errors: []});

    // check that user added an email
    if (!this.state.email) {
      return this.setState({errors: ['Please provide an email']});
    }
    // check if its a valid email
    if (!validate(this.state.email)) {
      return this.setState({errors: ['That is not a valid email']});
    }
    // check that they give a password
    if (!this.state.password) {
      return this.setState({errors: ['Please provide a password']});
    }

    this.setState({loading: true});
    try {
      await AuthHelpers.handleLogin({
        email: this.state.email.toLowerCase(),
        password: this.state.password,
      });
    } catch (err) {
      let errMessage = err.message.replace('GraphQL', '');
      if (err && err.message.includes('Incorrect password [403]')) {
        errMessage = 'You have entered an invalid username or password';
      }
      return this.setState({
        loading: false,
        errors: [errMessage],
      });
    }
    await ApolloClient.resetStore();
  };

  onSuccessfulLogin = ({access_token, refresh_token}) => {
    this.setState({loading: false});
    setTimeout(() => window.location.reload(), 800);
  };

  render() {
    return (
      <Background>
        <div>
          <FormContainer onSubmit={this.onSubmit}>
            <img
              src={logo}
              height="180"
              alt="logo"
              style={{marginBottom: 16, position: 'relative', left: -24}}
            />
            <div>
              <FormItem>
                <TextInput
                  label="email address"
                  value={this.state.email}
                  onChange={(e) => this.setState({email: e.target.value})}
                />
              </FormItem>
              <FormItem>
                <TextInput
                  label="password"
                  type="password"
                  value={this.state.password}
                  onChange={(e) => this.setState({password: e.target.value})}
                />
              </FormItem>
              {this.state.errors && this.state.errors.length > 0 && (
                <FormItem>
                  <ErrorBlock errors={this.state.errors} />
                </FormItem>
              )}
              <FormItem>
                <Button
                  onClick={this.onSubmit}
                  style={{width: 100}}
                  type="submit"
                  disabled={this.state.loading}
                >
                  {this.state.loading ? '...' : 'login'}
                </Button>{' '}
              </FormItem>
            </div>
          </FormContainer>
        </div>
      </Background>
    );
  }
}

export default AuthLogin;
