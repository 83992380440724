// TOP LEVEL IMPORTS
import React from 'react';
import {LoadingOutlined} from '@ant-design/icons';

export default function Loading() {
  return (
    <div
      style={{
        height: 500,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <LoadingOutlined />
    </div>
  );
}
