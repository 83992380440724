import React from 'react';
// APOLLO
import REPORT_ORDERS from 'ApolloClient/Queries/reportOrders';
import {useQuery} from '@apollo/client';
// COMPONENTS
import Table from 'components/common/Table';

const COLUMNS = [
  {
    title: 'UNDER EXECUTION',
    key: 'underExecution',
    render: (record) => record.underExecution,
  },
  {
    title: 'EXECUTED',
    render: (record) => record.executed,
  },
  {
    title: 'TOTAL',
    render: (record) => record.total,
  },
];

export default function OrdersReport({startDate, endDate}) {
  const {data, loading, error} = useQuery(REPORT_ORDERS, {
    variables: {
      startDate,
      endDate,
    },
    // fetchPolicy: 'network-only',
    skip: !endDate || !startDate,
  });
  if (loading) return 'loading...';
  if (error) return 'error...';

  return (
    <Table
      columns={COLUMNS}
      dataSource={data && [data.reportOrders]}
      rowKey="underExecution"
    />
  );
}
