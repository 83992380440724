import React, {useState} from 'react';
// COMPONENTS
import Table from 'components/common/Table';
import Button from 'components/common/Button';
import SimpleForm from 'components/common/SimpleForm';
import LinkButton from 'components/common/LinkButton';
// APOLLO CLIENT
import VENDORS from 'ApolloClient/Queries/vendors';
import SAVE_VENDOR from 'ApolloClient/Mutations/saveVendor';
import {useQuery, useMutation} from '@apollo/client';

const LIMIT = 5;

export default function AdminVendors() {
  const [showForm, setShowForm] = useState(false);
  const [saving, setSaving] = useState(false);
  const [selected, setSelected] = useState(null);
  const [errors, setErrors] = useState([]);
  const {data, loading} = useQuery(VENDORS, {
    variables: {
      limit: 1000,
    },
  });
  const [saveVendor] = useMutation(SAVE_VENDOR);
  const COLUMNS = [
    {
      title: 'Vendor',
      key: 'id',
      render: (record) => (
        <LinkButton onClick={() => setSelected(record)}>
          {record.title}
        </LinkButton>
      ),
    },
  ];

  let refetchQueries = [
    {
      query: VENDORS,
      variables: {
        limit: 1000,
      },
    },
  ];

  const onSubmit = async (title) => {
    try {
      setSaving(true);
      await saveVendor({
        variables: {
          title,
        },
        refetchQueries,
      });
      setSaving(false);
      setShowForm(false);
      setErrors([]);
    } catch (err) {
      setSaving(false);
      setErrors([err.message]);
    }
  };

  const onSave = async (title) => {
    try {
      if (!selected || !selected.id) return null;
      setSaving(true);

      await saveVendor({
        variables: {
          id: selected.id,
          title,
        },
        refetchQueries,
      });
      setSaving(false);
      setSelected(null);
      setErrors([]);
    } catch (err) {
      setSaving(false);
      setErrors([err.message]);
    }
  };

  if (showForm) {
    return (
      <SimpleForm
        onCancel={() => setShowForm(false)}
        onSubmit={onSubmit}
        saving={saving}
        errors={errors}
        placeholder="Vendor name here"
      />
    );
  }

  if (selected) {
    return (
      <SimpleForm
        onCancel={() => setShowForm(false)}
        onSubmit={onSave}
        saving={saving}
        errors={errors}
        defaultValue={selected.title}
        placeholder="Vendor name here"
      />
    );
  }

  return (
    <div style={{marginTop: 32}}>
      <Table
        loading={loading}
        columns={COLUMNS}
        dataSource={(!loading && data && data.vendors) || []}
        pagination={{
          pageSize: LIMIT,
          total: (data && data.vendors && data.vendors.length) || 0,
        }}
      />
      <Button onClick={() => setShowForm(true)} style={{width: 170}}>
        Create Vendor
      </Button>
    </div>
  );
}
