import React from 'react';
import theme, {GlobalStyle} from 'lib/theme';
import {ThemeProvider} from 'styled-components';
import AppRoutes from './routes/index';
import {ApolloProvider} from '@apollo/client';
import client from 'ApolloClient/index.js';
import moment from 'moment-timezone';
import constants from 'lib/constants';

moment.tz.setDefault(constants.timezone);

function App() {
  // return 'Under Maintenance';
  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <>
          <AppRoutes />
          <GlobalStyle />
        </>
      </ThemeProvider>
    </ApolloProvider>
  );
}

export default App;
