// TOP LEVEL IMPORTS
import {gql} from '@apollo/client';

export default gql`
  mutation deleteEntry($id: ID!) {
    deleteEntry(id: $id) {
      success
      errors
    }
  }
`;
