import React, {useState} from 'react';
import RangePicker from 'components/inputs/RangePicker';
import styled from 'styled-components';
import moment from 'moment';

const BigDate = styled.h1`
  font-size: 32px;
  margin-bottom: 0px;
  margin-top: 16px;
`;

const ShowBtn = styled.button`
  border: 0px;
  color: ${(p) => p.theme.colors.primary};
  outline: 0;
  background: transparent;
  text-decoration: underline;
  cursor: pointer;
`;

const DatePickerBackground = styled.div`
  position: fixed;
  background: transparent;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 100;
`;

export default function RangeComponent({onChange, value = []}) {
  const [showPicker, setShowPicker] = useState(false);

  const handleChange = (dates) => {
    onChange({
      startDate: dates[0],
      endDate: dates[1],
    });
    setShowPicker(false);
  };

  return (
    <div style={{height: 108, display: 'flex', justifyContent: 'flex-end'}}>
      <div>
        <BigDate>
          {value[0] && value[0].format('MMMM DD, YYYY')} -{' '}
          {value[1] && value[1].format('MMMM DD, YYYY')}
        </BigDate>
        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
          <ShowBtn onClick={() => setShowPicker(true)}>
            Change Date Range
          </ShowBtn>
        </div>
        <div style={{opacity: 0}}>
          <RangePicker
            open={showPicker}
            value={[]}
            onChange={handleChange}
            disabledDate={(currentDate) => {
              let greaterThanToday =
                currentDate.startOf('day').valueOf() >
                moment().startOf('day').valueOf();
              return greaterThanToday;
            }}
            allowClear={false}
          />
        </div>
      </div>
      {showPicker && (
        <DatePickerBackground onClick={() => setShowPicker(false)} />
      )}
    </div>
  );
}
