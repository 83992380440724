import React from 'react';
import styled from 'styled-components';
import {CloseCircleFilled} from '@ant-design/icons';

const ErrorBlockContainer = styled.div`
  background: ${(p) => p.theme.colors.red10};
  border-left: 4px solid ${(p) => p.theme.colors.red4};
  padding: 8px;
  border-radius: 5px;
  color: ${(p) => p.theme.colors.red2};
  font-size: 13px;
  margin-bottom: 16px;
  display: flex;
`;

const ErrorBlock = ({errors = [], error}) => (
  <ErrorBlockContainer>
    <CloseCircleFilled style={{marginRight: 4, fontSize: 18}} />
    {errors && errors.map((item) => item)}
    {error && error}
  </ErrorBlockContainer>
);

export default ErrorBlock;
