import React from "react";
import Select from "components/inputs/SelectInput";
import DropdownStyleWrapper from "components/inputs/DropdownStyleWrapper";

const { Option } = Select;

export default function OrderTypeInput({ value, onChange }) {
  return (
    <DropdownStyleWrapper>
      <Select
        value={value}
        placeholder='Order Type'
        style={{ width: "100%" }}
        onChange={onChange}
      >
        <Option value='repeatOrder'>REPEAT ORDER</Option>
        <Option value='newOrder'>NEW ORDER</Option>
        <Option value='molds'>MOLDS</Option>
        <Option value='samples'>SAMPLES</Option>
        <Option value='tod'>TOD</Option>
      </Select>
    </DropdownStyleWrapper>
  );
}
