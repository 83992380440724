// TOP LEVEL IMPORTS
import {gql} from '@apollo/client';

export default gql`
  mutation saveEntry($id: ID, $params: EntryInput) {
    saveEntry(id: $id, params: $params) {
      id
    }
  }
`;
