// TOP LEVEL IMPORTS
import { gql } from '@apollo/client';

export default gql`
  mutation deleteChildSplit($parentId: ID!, $id: ID!) {
    deleteChildSplit(parentId: $parentId, id: $id) {
      success
      errors
    }
  }
`;
